import { useState, useEffect, useCallback } from "react";
import { Box, Tabs, Tab, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

import VisitList, { VisitListSkeleton } from "../components/VisitList";
import { isToday, isFuture, parseISO, compareAsc, compareDesc } from "date-fns";
import { toDate } from "date-fns-tz";
import { ROUTES } from "../../../config/routes";

import useVisits from "../hooks/useVisits";

const Visits = () => {
  const [tabValue, setTabValue] = useState(0);
  const { visits, getVisits, hasCachedVisits } = useVisits();

  console.log(visits);

  const navigate = useNavigate();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isActiveVisit = useCallback(
    (visit: any) => {
      if (!visit?.invitation?.date) return false;
      const visitDate = toDate(parseISO(visit.invitation.date), {
        timeZone: userTimeZone,
      });
      return isToday(visitDate) || isFuture(visitDate);
    },
    [userTimeZone]
  );

  useEffect(() => {
    if (!hasCachedVisits) {
      getVisits();
    }
  }, []);

  const handleTabChange = (_event: any, newValue: number) => {
    setTabValue(newValue);
  };

  const handleVisitClick = (visitId: any) => {
    navigate(ROUTES.VISIT_DETAIL.replace(":visitId", visitId));
  };

  const getFilteredAndSortedVisits = () =>
    visits.data
      .filter((visit) => visit?.invitation?.date)
      .filter((visit) =>
        tabValue === 0 ? isActiveVisit(visit) : !isActiveVisit(visit)
      )
      .sort((a, b) => {
        const dateA = toDate(parseISO(a.invitation.date), {
          timeZone: userTimeZone,
        });
        const dateB = toDate(parseISO(b.invitation.date), {
          timeZone: userTimeZone,
        });
        return tabValue === 0
          ? compareAsc(dateA, dateB)
          : compareDesc(dateA, dateB);
      });

  if (visits.error)
    return (
      <Box sx={{ p: 3, textAlign: "center" }}>
        <Typography color="error">{visits.error}</Typography>
      </Box>
    );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="visit tabs"
        variant="fullWidth"
        centered
      >
        <Tab label="Activas" />
        <Tab label="Historial" />
      </Tabs>
      <Box sx={{ px: 1 }}>
        {visits.loading ? (
          <VisitListSkeleton />
        ) : (
          <VisitList
            visits={getFilteredAndSortedVisits()}
            isActiveTab={tabValue === 0}
            onVisitClick={handleVisitClick}
          />
        )}
      </Box>
    </Box>
  );
};

export default Visits;

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import theme from "../../styles/theme";
import { Article, Home, People, Warning } from "@mui/icons-material";
import { ROUTES } from "../../config/routes";
import { Link } from "react-router-dom";

export const DRAWER_WIDTH = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...theme.mixins.toolbar,

  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

type MainSidebarProps = {
  open: boolean;
  handleToggleSidebar: () => void;
  handleCloseSidebar: () => void;
};

const menuItems = [
  { text: "Resumen", icon: <Home />, route: ROUTES.SUMMARY, premium: false },

  {
    text: "Mantenimiento",
    icon: <Article />,
    route: ROUTES.MAINTENANCE,
    premium: false,
  },
  {
    text: "Visitas",
    icon: <People />,
    route: ROUTES.VISITS,
    premium: false,
  },
  {
    text: "Incidencias",
    icon: <Warning />,
    route: ROUTES.INCIDENTS,
    premium: false,
  },
];

function MainSidebar({ open, handleCloseSidebar }: MainSidebarProps) {
  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
          border: "none",
        },
      }}
      onClose={handleCloseSidebar}
      variant="permanent"
      open={open}
    >
      {/**   necessary for content to be below app bar */}
      <DrawerHeader />
      <List>
        {menuItems.map((item) => (
          <Link
            key={item.route}
            style={{ textDecorationLine: "none" }}
            to={item.route}
          >
            <ListItem
              key={item.text}
              disablePadding
              sx={{
                display: "block",
                bgcolor:
                  item.route === location.pathname ? "#E9EDFF" : "inherit",
                color:
                  item.route === location.pathname
                    ? "#315CAC"
                    : theme.palette.text.primary,
                "&.Mui-disabled": {
                  opacity: 0.5,
                },
              }}
            >
              <ListItemButton
                sx={[
                  {
                    minHeight: 48,
                  },
                  open
                    ? {
                        justifyContent: "initial",
                      }
                    : {
                        justifyContent: "center",
                      },
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      minWidth: 0,
                      justifyContent: "center",
                      color:
                        item.route === location.pathname
                          ? "#315CAC"
                          : theme.palette.text.primary,
                    },
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: "auto",
                        },
                  ]}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer>
  );
}

export default MainSidebar;

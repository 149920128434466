// src/shared/components/LoadingSpinner.tsx

import { CircularProgress, Box } from "@mui/material";

const LoadingSpinner = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#F4F8FA",
    }}
  >
    <CircularProgress />
  </Box>
);

export default LoadingSpinner;

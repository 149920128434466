import { useEffect, useState } from "react";

import {
  IconButton,
  Typography,
  Box,
  Chip,
  Paper,
  CircularProgress,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageViewerVaucher from "./components/ImageViewerVaucher";
import { secureApi } from "../../supabase/secureApi";

import { paymentProps } from "./types";
import { MONTHS_IN_SPANISH } from "../../constants/months-in-spanish";
import useMaintenanceFees from "./hooks/useMaintenanceFees";
import usePayments from "./hooks/usePayments";
import { Payment } from "../../supabase/type/db-entities";
import formatInvoiceRegistrationDate from "./utils/formatInvoiceRegistrationDate";
import { useMaintenanceFeesAnalytics } from "./hooks/useMaintenanceFeesAnalytics";
export default function ModalDetailPayment({
  open,
  onClose,
  transactionId,
}: {
  open: boolean;
  onClose: () => void;
  transactionId: string | number;
}) {
  const [loading, setLoading] = useState(false);

  const { findPaymentById } = usePayments();

  const cachedPayment = findPaymentById(String(transactionId));
  const { trackPaymentViewed } = useMaintenanceFeesAnalytics();

  const [detailsPayment, setDetailsPayment] = useState<Payment>(cachedPayment || {} as Payment);
  const getPaymentDetails = async () => {
    if (!transactionId || cachedPayment) return;

    try {
      setLoading(true);
      const data = await secureApi.get("payments", {
        select: "*",
        filter: { id: transactionId },
        single: true,
      });

      setDetailsPayment(data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch  payment details:", error);
      throw new Error("Failed to fetch payment details");
    }
  };

  useEffect(() => {
    if (transactionId && open) {
      getPaymentDetails();
      trackPaymentViewed(String(transactionId));
    }
  }, [transactionId, open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0)",
        opacity: 0.5,
        backdropFilter: "blur(1px)",
      }}
    >
      <Box width={600} sx={{ p: 3, bgcolor: "white", borderRadius: 2, mr: 2 }}>
        {loading ? (
          <Box
            sx={{
              width: 600,
              height: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                pb: 1,
              }}
            >
              <Typography variant="h6">Detalle Registro de Voucher</Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box
              sx={{
                borderBottom: 1,
                borderBottomColor: "#f1f0f4",
                borderBottomWidth: 1,
              }}
            />
            <Box>
              {/* Información General */}
              <Box>
                <Box sx={{ mb: 2 }}>
                  <Box>
                    <Box>
                      <Typography color="text.secondary">
                        Fecha de registro
                      </Typography>
                      <Typography>
                        {detailsPayment?.created_at
                          ? formatInvoiceRegistrationDate(
                              detailsPayment?.created_at
                            )
                          : null}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary">Periodo</Typography>
                      <Typography>
                        {
                          MONTHS_IN_SPANISH[
                            new Date(
                              detailsPayment?.payment_date || ""
                            ).getMonth()
                          ]
                        }{" "}
                        {new Date(
                          detailsPayment?.payment_date || ""
                        ).getFullYear()}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary">Estado</Typography>
                      {(() => {
                        const status = detailsPayment?.status || 'pending';
                        const statusText = status === "pending" ? "Por Validar" : "Validado";
                        const bgColor = status === "pending" ? "#FEEBC8" : "#C6F6D5";
                        const textColor = status === "pending" ? "#D69E2E" : "#2F855A";

                        return (
                          <Chip
                            label={statusText}
                            size="small"
                            sx={{
                              backgroundColor: bgColor,
                              color: textColor,
                              mt: 0.5,
                            }}
                          />
                        );
                      })()}
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Información del Voucher */}
              <Box>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Información del Voucher
                </Typography>
                <Box sx={{ bgcolor: "#F8F9FA", p: 2, borderRadius: 1 }}>
                  <Box>
                    <Box>
                      <Typography color="text.secondary">
                        Fecha de transacción
                      </Typography>
                      <Typography>{detailsPayment?.payment_date}</Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary">Valor</Typography>
                      <Typography>s/ {detailsPayment?.amount}</Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary">Detalle</Typography>
                      <Typography>{detailsPayment?.notes}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Archivo Adjunto */}
              <Box>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Archivo Adjunto
                </Typography>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: 2,
                  }}
                >
                  {detailsPayment?.evidence_path ? (
                    <ImageViewerVaucher
                      pathImage={detailsPayment?.evidence_path!}
                      nameImage={`pago${detailsPayment?.bank_name || 'banco'}${detailsPayment?.payment_date}`}
                     
                    />
                  ) : (
                    <Typography>No hay archivo adjunto</Typography>
                  )}
                </Paper>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

// src/features/incident/pages/CreateIncident.tsx

import { Container, Typography, Box, Alert, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { IncidentForm } from "../components/create";

import { ROUTES } from "../../../config/routes";
import { Link } from "react-router-dom";

const CreateIncident = () => {
  return (
    <Box>
      <Box display="flex" alignItems="center" mb={3}>
        <Link to={ROUTES.INCIDENTS}>
          <IconButton sx={{ mr: 2 }}>
            <ArrowBack />
          </IconButton>
        </Link>
        <Typography variant="h5" gutterBottom>
          Reportar Incidencia
        </Typography>
      </Box>

      <IncidentForm />
    </Box>
  );
};

export default CreateIncident;

import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';

export const useMaintenanceFeesAnalytics = () => {
  const { track } = useAnalytics();
 
  const trackVoucherUploaded = useCallback((params: {
    userId: string;
    buildingId: string;
    amount: number; 
  }) => {
    track(EVENT_TYPES.MAINTENANCE_VOUCHER_UPLOADED, params);
  }, [track]);

  const trackPaymentCompleted = useCallback((params: {
    userId: string;
    buildingId: string;
    apartmentId: string;
    amount: number;
    paymentMethod: string;
    transactionId: string;
  }) => {
    track(EVENT_TYPES.MAINTENANCE_PAYMENT_COMPLETED, params);
  }, [track]);

  const trackPaymentViewed = useCallback((transactionId: string) => {
    track(EVENT_TYPES.MAINTENANCE_PAYMENT_DETAIL_VIEWED, { transaction_id: transactionId });
  }, [track]);

  const trackBankDetailsCopied = useCallback((fieldType :any, bankName: string) => {
    track(EVENT_TYPES.MAINTENANCE_BANK_DETAILS_COPIED, {
      field_type: fieldType,
      bank_name: bankName
    });
  }, [track]);

  return {
    trackVoucherUploaded,
    trackPaymentCompleted,
    trackPaymentViewed, 
    trackBankDetailsCopied
  };
};
// src/features/invitation/pages/InvitationLanding.tsx

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  Stack,
  Divider,
  Snackbar,
  Skeleton,
  Alert,
  AlertProps,
} from "@mui/material";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import {
  CalendarToday,
  AccessTime,
  LocationOn,
  Apartment,
} from "@mui/icons-material";
import { invitationService } from "../services/invitationService";
import { useInvitation } from "../hooks/useInvitation";
import { useAuth } from "../../auth/hooks/useAuth";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useInvitationAnalytics } from "../hooks/useInvitationAnalytics";
import { ROUTES } from "../../../config/routes";
import useVisits from "../hooks/useVisits";
import { useBuilding } from "../../building/hooks/useBuilding";
import useGetBuildingData from "../../building/hooks/useGetBuidingData";

type InvitationCreationStatus =
  | "pending"
  | "already-accepted"
  | "accepted"
  | "failed";

const getMessageSeverity = (
  status: InvitationCreationStatus
): AlertProps["severity"] => {
  switch (status) {
    case "accepted":
      return "success";

    case "already-accepted":
      return "warning";

    case "failed":
      return "error";

    default:
      return "info";
  }
};

const InvitationLanding = () => {
  const { setInvitationToken, setInvitationId, createVisitFromInvitation } = useInvitation();
  const [invitation, setInvitation] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { inviteToken } = useParams();
  const { user } = useAuth();
  const { getVisits } = useVisits();
  const navigate = useNavigate();
  const { trackInvitationViewed } = useInvitationAnalytics();

  const { getBuildingAssociatedData } = useGetBuildingData();

  const { apartmentId, buildingId } = useBuilding();

  const location = useLocation();

  const [creatingInvitation, setCreatingInvitation] = useState(false);
  const [status, setStatus] = useState<InvitationCreationStatus>("pending");

  const fetchInvitationByToken = async (inviteToken: string) => {
    try {
      const data = await invitationService.fetchInvitationByToken(inviteToken);
      setInvitation(data);
      setInvitationId(data.id);
      if (user) {
        trackInvitationViewed(data.id, user.id);
      }
    } catch (err: any) {
      console.error("Error fetching invitation:", err);
      setError(
        err.message || "An error occurred while fetching the invitation"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inviteToken) {
      setInvitationToken(inviteToken);
      fetchInvitationByToken(inviteToken);
    } else {
      setError("No invitation token provided");
      setLoading(false);
    }
  }, [inviteToken, setInvitationToken, user, trackInvitationViewed]);

  const acceptInvitation = async () => {
    if (!user) {
      setError("User not authenticated");
      return;
    }
    try {
      const invitationToken = inviteToken;
      const invitationId = invitation.id;

      if (!invitationToken || !invitationId) return;
      setCreatingInvitation(true);
      const { isExisting } = await createVisitFromInvitation({
        invitationId,
        invitationToken, 
      });

      if (isExisting) {
        setStatus("already-accepted");
        setSnackbarMessage("Ya has aceptado esta invitación anteriormente.");
      } else {
        setStatus("accepted");
        setSnackbarMessage("Invitación aceptada exitosamente.");
      }

      // Synchronize  initial  information

      const requests = [getVisits()];

      if (buildingId && apartmentId) {
        requests.push(getBuildingAssociatedData({ buildingId, apartmentId }));
      }
      await Promise.all(requests);
      setSnackbarOpen(true);
      const HIDE_OPERATION_COMPLETION_SNACKBAR_DELAY = 3000; // 3 seconds

      setTimeout(() => {
        setSnackbarOpen(false);
        setCreatingInvitation(false);

        navigate(ROUTES.VISITS);
      }, HIDE_OPERATION_COMPLETION_SNACKBAR_DELAY);
    } catch (error: any) {
      console.error("Error processing invitation:", error);
      setError("Error al procesar la invitación: " + error.message);

      setStatus("failed");
      setSnackbarMessage(
        "Ocurrió un error al procesar la invitación. Por favor, intenta de nuevo."
      );
      setSnackbarOpen(true);
    }
  };

  const ContentContainer = ({ children }: { children: React.ReactNode }) => (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        bgcolor="background.paper"
        p={2}
        borderRadius={2}
        width={{ sm: "100%", md: "90%", lg: "60%" }}
      >
        {children}
      </Box>
    </Box>
  );

  if (loading) {
    return (
      <ContentContainer>
        <InvitationDetailSkeleton />
      </ContentContainer>
    );
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  if (!invitation) {
    return (
      <ContentContainer>
        <Typography>Invitación no encontrada.</Typography>;
      </ContentContainer>
    );
  }

  const residentName = invitation.resident
    ? `${invitation.resident.first_name} ${invitation.resident.last_name}`
    : "Unknown";
  const visitDate = invitation.date ? new Date(invitation.date) : null;

  const withRedirectUrl = (path: string) =>
    `${path}?redirect_to=${location.pathname}`;

  return (
    <ContentContainer>
      <Typography variant="h4" gutterBottom>
        Bienvenido
      </Typography>
      <Typography variant="body1" paragraph>
        Te han invitado a realizar una visita.{" "}
        {user
          ? "Continúa para confirmar tu asistencia."
          : "Para confirmar tu asistencia, inicia sesión o regístrate."}
      </Typography>
      <Box sx={{ bgcolor: "background.paper", p: 2, borderRadius: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Invitación de:
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center" mb={2}>
          <Avatar src={invitation.resident?.profile_pic} alt={residentName}>
            {residentName.charAt(0)}
          </Avatar>
          <Box>
            <Typography variant="subtitle1">{residentName}</Typography>
            {visitDate && (
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <CalendarToday fontSize="small" />
                  <Typography variant="body2">
                    {format(visitDate, "EEEE d 'de' MMMM, yyyy", {
                      locale: es,
                    })}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <AccessTime fontSize="small" />
                  <Typography variant="body2">
                    {format(visitDate, "HH:mm")}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Box>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>
          Lugar de la visita:
        </Typography>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Apartment fontSize="small" />
            <Typography variant="body2">
              Apartamento: {invitation.apartment?.apartment_number}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <LocationOn fontSize="small" sx={{ mt: 0.5 }} />
            <Box>
              <Typography variant="body2">
                {invitation.apartment?.building?.name}
              </Typography>
              <Typography variant="body2">
                {invitation.apartment?.building?.address}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      {user ? (
        <Button
          onClick={acceptInvitation}
          variant="contained"
          fullWidth
          disabled={creatingInvitation}
        >
          Continuar
        </Button>
      ) : (
        <>
          <Link to={withRedirectUrl(ROUTES.LOGIN)}>
            <Button variant="contained" fullWidth sx={{ mb: 2 }}>
              Iniciar sesión
            </Button>
          </Link>
          <Link to={withRedirectUrl(ROUTES.REGISTER)}>
            <Button variant="outlined" fullWidth>
              Soy nuevo, registrarme
            </Button>
          </Link>
        </>
      )}
      <Typography variant="body2" align="center" sx={{ mt: 2 }}>
        Al iniciar sesión o registrarse, acepta nuestros{" "}
        <Link to={ROUTES.TERMS} target="_blank" rel="noopener noreferrer">
          Términos de servicio
        </Link>{" "}
        y{" "}
        <Link to={ROUTES.PRIVACY} target="_blank" rel="noopener noreferrer">
          Política de Privacidad
        </Link>
      </Typography>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={getMessageSeverity(status)}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ContentContainer>
  );
};

const InvitationDetailSkeleton = () => {
  return (
    <Box>
      <Skeleton width="30%" height={20} />
      <Stack direction="row" spacing={2} alignItems="center" mb={2}>
        <Skeleton width={40} height={40} variant="circular" />
        <Box width="100%">
          <Skeleton width="40%" height={20} />
          <Stack spacing={1} direction="column">
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton width={20} height={20} variant="circular" />
              <Skeleton width="40%" height={20} />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton width={20} height={20} variant="circular" />
              <Skeleton width="60%" height={20} />
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Skeleton width="50%" height={20} />
      <Stack spacing={1} width="100%">
        <Stack width="100%" direction="row" spacing={1} alignItems="center">
          <Skeleton width={20} height={20} variant="circular" />
          <Skeleton width="60%" height={20} />
        </Stack>
        <Stack width="100%" direction="row" spacing={1} alignItems="flex-start">
          <Skeleton width={20} height={20} variant="circular" />
          <Box width="100%">
            <Skeleton width="50%" height={20} />
            <Skeleton width="40%" height={20} />
            <Skeleton width="60%" height={20} />
            <Skeleton width="30%" height={20} />
          </Box>
        </Stack>
      </Stack>

      <Stack marginTop={2} direction="column" spacing={2}>
        <Skeleton
          width="100%"
          height={40}
          variant="rounded"
          sx={{ borderRadius: 6 }}
        />
        <Skeleton
          width="100%"
          height={40}
          variant="rounded"
          sx={{ borderRadius: 6 }}
        />
      </Stack>
    </Box>
  );
};

export default InvitationLanding;

// src/features/invitation/components/VisitCard.tsx

import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Skeleton,
} from "@mui/material";
import { CalendarToday, AccessTime, ArrowForward } from "@mui/icons-material";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import StatusChip from "./StatusChip";

// Definir los tipos de las propiedades de `visit` y `onVisitClick`
interface Resident {
  first_name: string;
  last_name: string;
}

interface Invitation {
  date: string;
  resident: Resident;
}

interface Visit {
  id: string;
  status: "pending" | "checked_in" | "cancelled"; // Usar el tipo adecuado de status
  invitation: Invitation;
}

interface VisitCardProps {
  visit: Visit;
  onVisitClick: (id: string) => void;
}

const VisitCard = ({ visit, onVisitClick }: VisitCardProps) => {
  const visitDate = new Date(visit.invitation.date);

  return (
    <Card sx={{ mb: 2, borderRadius: 4 }}>
      <CardContent sx={{ p: 2 }}>
        <Typography variant="h6" component="div" gutterBottom>
          {visit.invitation.resident.first_name}{" "}
          {visit.invitation.resident.last_name}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <CalendarToday fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            {format(visitDate, "d 'de' MMMM, yyyy", { locale: es })}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <AccessTime fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            {format(visitDate, "HH:mm")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <StatusChip status={visit.status} />
          <IconButton
            onClick={() => onVisitClick(visit.id)}
            sx={{
              bgcolor: "action.selected",
              borderRadius: 2,
              "&:hover": {
                bgcolor: "action.hover",
              },
            }}
          >
            <ArrowForward />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export const VisitCardSkeleton = () => (
  <Card sx={{ mb: 2, borderRadius: 4 }}>
    <CardContent sx={{ p: 2 }}>
      <Skeleton width={120} height={24} variant="text" />
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Skeleton width={160} height={18} variant="text" />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Skeleton width={150} height={18} variant="text" />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Skeleton width={80} height={32} variant="rounded" />

        <Skeleton width={40} height={40} variant="rounded" />
      </Box>
    </CardContent>
  </Card>
);

export default VisitCard;

// src/features/incident/services/incidentService.ts

import { secureApi } from "../../../supabase/secureApi";
import { incidentStorageService } from "./storageService";

export const incidentService = {
  async createIncident(incidentData: any, userId: any) {
    try {
      console.log("Creating incident with data:", incidentData);

      if (!incidentData.building_id) {
        throw new Error("building_id is required");
      }

      // First create the incident to get the real ID
      const params = {
        p_incident_data: {
          title: incidentData.title,
          description: incidentData.description,
          category: incidentData.category,
          priority: incidentData.priority,
          location_details: incidentData.location_details,
          metadata: incidentData.metadata || {},
          media_urls: [], // Start with empty media
          incident_visibility: incidentData.incident_visibility || "community", // Ensure this matches the enum type
        },
        p_user_id: userId,
        p_building_id: incidentData.building_id,
        p_operation: "create",
      };

      console.log("Sending RPC params:", params);

      const result = await secureApi.rpc("manage_incident", params);
      if (!result.success) {
        throw new Error(result.message || "Failed to create incident");
      }

      const incidentId = result.data.incident_id;
      let mediaUrls: any = [];

      // Then upload media if any using the real incident ID
      if (incidentData.media && incidentData.media.length > 0) {
        console.log("Uploading media files:", {
          buildingId: incidentData.building_id,
          incidentId,
          fileCount: incidentData.media.length,
        });

        const results = await incidentStorageService.uploadMultipleFiles(
          incidentData.media,
          {
            buildingId: incidentData.building_id,
            incidentId,
            type: "main",
          }
        );

        // Store the complete media information
        mediaUrls = results.map((result) => ({
          url: result.url,
          path: result.path,
          size: result.size,
          type: result.type,
        }));

        console.log("Uploaded media files:", mediaUrls);

        // Update the incident with the full media URLs metadata
        if (mediaUrls.length > 0) {
          await secureApi.rpc("manage_incident", {
            p_incident_data: {
              id: incidentId,
              media_urls: mediaUrls, // Send the complete media objects
            },
            p_user_id: userId,
            p_building_id: incidentData.building_id,
            p_operation: "update",
          });
        }
      }

      return {
        success: true,
        data: {
          ...result.data,
          media_urls: mediaUrls,
          building_id: incidentData.building_id,
        },
      };
    } catch (error) {
      console.error("Error in createIncident:", error);
      throw error;
    }
  },

  async updateIncidentStatus(
    incidentId: any,
    newStatus: any,
    userId: any,
    notes = null
  ) {
    try {
      return await secureApi.rpc("update_incident_status", {
        p_incident_id: incidentId,
        p_new_status: newStatus,
        p_user_id: userId,
        p_notes: notes,
      });
    } catch (error) {
      console.error("Error updating incident status:", error);
      throw error;
    }
  },

  async addComment(
    incidentId: any,
    userId: any,
    commentText: any,
    mediaFiles = [],
    isInternal = false,
    buildingId: any
  ) {
    try {
      if (!commentText || typeof commentText !== "string") {
        throw new Error("Comment must be a non-empty string");
      }

      if (!incidentId) {
        throw new Error("Incident ID is required");
      }

      const commentId = crypto.randomUUID();
      let mediaUrls: any = [];

      if (Array.isArray(mediaFiles) && mediaFiles.length > 0) {
        if (!buildingId) {
          throw new Error("Building ID is required for media upload");
        }

        console.log("Uploading comment media files:", {
          buildingId,
          incidentId,
          commentId,
          fileCount: mediaFiles.length,
        });

        const results = await incidentStorageService.uploadMultipleFiles(
          mediaFiles,
          {
            buildingId,
            incidentId,
            type: "comments",
            subId: commentId,
          }
        );

        // Transform the storage results into public URLs
        mediaUrls = results.map((result) => ({
          url: result.url,
          path: result.path,
          type: result.type,
        }));

        console.log("Processed media URLs:", mediaUrls);
      }

      const response = await secureApi.rpc("add_incident_comment", {
        p_incident_id: incidentId,
        p_user_id: userId,
        p_comment: commentText.trim(),
        p_media_urls: mediaUrls, // Remove JSON.stringify here
        p_is_internal: isInternal,
      });

      if (!response.success) {
        throw new Error(response.message || "Failed to add comment");
      }

      return response;
    } catch (error) {
      console.error("Error adding comment:", error);
      throw error;
    }
  },

  async fetchIncidentById(incidentId: any, userId: any) {
    try {
      if (!incidentId || !userId) {
        throw new Error("Missing required parameters");
      }

      const response = await secureApi.rpc("get_incident_by_id", {
        p_incident_id: incidentId,
        p_user_id: userId,
      });

      console.log("Raw RPC response:", response);

      if (!response?.success) {
        throw new Error(response?.message || "Failed to fetch incident");
      }

      // Debug the nested data structure
      console.log("Response data structure:", {
        hasData: !!response.data,
        dataKeys: Object.keys(response.data),
        commentsArray: Array.isArray(response.data?.comments),
        commentsLength: response.data?.comments?.length,
      });

      const { incident, comments, status_history } = response.data;

      if (!incident) {
        throw new Error("No incident data found");
      }

      // Transform and merge the data, making sure building_id is included
      const transformedData = {
        ...incident,
        building_id: incident.building?.id, // Extract building_id from the nested building object
        comments: Array.isArray(comments)
          ? comments.map((comment) => ({
              ...comment,
              created_at: new Date(comment.created_at),
              updated_at: new Date(comment.updated_at),
              media_urls: Array.isArray(comment.media_urls)
                ? comment.media_urls
                : [],
              user: comment.user || {}, // Ensure user object exists
            }))
          : [],
        status_history: Array.isArray(status_history)
          ? status_history.map((status) => ({
              ...status,
              changed_at: new Date(status.changed_at),
            }))
          : [],
      };

      console.log("Transformed data:", {
        hasComments: transformedData.comments.length > 0,
        commentsCount: transformedData.comments.length,
        firstComment: transformedData.comments[0],
      });

      return transformedData;
    } catch (error) {
      console.error("Error in fetchIncidentById:", error);
      throw error;
    }
  },

  async fetchUserIncidents(userId: string) {
    if (!userId) {
      throw new Error("User ID is required");
    }

    try {
      const response = await secureApi.rpc("get_user_incidents", {
        p_user_id: userId,
      });

      if (response.success && Array.isArray(response.data)) {
        return response.data.map((incident: any) => ({
          ...incident,
          created_at: new Date(incident.created_at),
          updated_at: new Date(incident.updated_at),
          due_date: incident.due_date ? new Date(incident.due_date) : null,
          resolution_date: incident.resolution_date
            ? new Date(incident.resolution_date)
            : null,
          media_urls: Array.isArray(incident.media_urls)
            ? incident.media_urls
            : [],
          metadata:
            typeof incident.metadata === "object" ? incident.metadata : {},
          comments_count: parseInt(incident.comments_count) || 0,
        }));
      }

      return [];
    } catch (err) {
      console.error("[Service] Error fetching incidents:", err);
      throw err;
    }
  },

  async fetchCommunityIncidents(buildingId: string, userId: string) {
    if (!buildingId) {
      throw new Error("User ID is required");
    }

    console.log("[Service] Fetching incidents for community:", buildingId);

    try {
      const response = await secureApi.rpc("get_community_incidents", {
        p_building_id: buildingId,
        p_user_id: userId,
      });

      return response || [];
    } catch (err) {
      console.error("[Service] Error fetching incidents:", err);
      throw err;
    }
  },
};

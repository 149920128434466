import { Box, Grid2 } from "@mui/material";

import HistoricalPaymentsTable from "./components/HistoricalPaymentsTable";
import { useAuth } from "../auth/hooks/useAuth";
import MessageGeneralWithoutBuilding from "../../shared/components/MessageGeneralWithoutBuilding";
import { useEffect, useState } from "react";
import { useBuilding } from "../building/hooks/useBuilding";

import BankingInformation from "./components/BankingInformation";
import MaintenancePaymentSummary from "./components/MaintenancePaymentSummary";
import usePayments from "./hooks/usePayments";
import { isValidNonEmptyArray } from "../../shared/utils/isValidNonEmptyArray";

export default function Maintenance() {
  const { user } = useAuth();

  const { payments } = usePayments();
  const hasAssociatedBuildings = isValidNonEmptyArray(user?.buildings);

  return (
    <Box>
      {hasAssociatedBuildings ? (
        <>
          <Grid2 container direction="row" spacing={4}>
            <Grid2 size={{ sm: 12, md: 6 }}>
              <MaintenancePaymentSummary direction="column" />
            </Grid2>
            <Grid2 size={{ sm: 12, md: 6 }}>
              <BankingInformation />
            </Grid2>
          </Grid2>
          <Box marginTop={2}>
            <HistoricalPaymentsTable
              payments={payments.data}
              loading={payments.loading}
            />
          </Box>
        </>
      ) : (
        <MessageGeneralWithoutBuilding isResident={false} name={""} />
      )}
    </Box>
  );
}

type Obj = Record<string, any>;
/**
 * Omit specified keys from an object
 * @param obj Source object
 * @param keys Keys to extract
 * @returns New object without omitted keys
 */

export default function omit<T extends Obj, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> {
  const newObj: Partial<Omit<T, K>> = {};

  for (const key in obj) {
    if (!keys.includes(key as K)) {
      newObj[key as keyof Omit<T, K>] = obj[key];
    }
  }

  return newObj as Omit<T, K>;
}

// src/features/auth/components/EmailInput.tsx

import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Typography,
  // Divider,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import trimText from "../../../shared/utils/trimText";

interface EmailInputProps {
  onSubmit: (email: string, password: string) => void;
}

const EmailInput = ({ onSubmit }: EmailInputProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [passwordError, setPasswordError] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (password.length < 8) {
      setPasswordError("La contraseña debe tener al menos 8 caracteres");
      return;
    }
    setPasswordError("");
    onSubmit(trimText(email), trimText(password));
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Crear Cuenta
      </Typography>

      {/* Email Section */}
      <Typography variant="subtitle1" gutterBottom>
        Ingresa tu correo electrónico
      </Typography>
      <TextField
        required
        label="Correo electrónico"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        placeholder="example@gmail.com"
      />

      {/* Divider */}
      {/* <Divider sx={{ my: 3 }} /> */}

      {/* Password Section */}
      {/* <Typography variant="subtitle1" gutterBottom>
        Crea una contraseña
      </Typography> */}
      <TextField
        required
        label="Contraseña"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
        placeholder="Al menos 8 caracteres"
        error={!!passwordError}
        helperText={passwordError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
                aria-label="toggle password visibility"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* Submit Button */}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 4, borderRadius: "8px" }}
      >
        Siguiente
      </Button>
    </Box>
  );
};

export default EmailInput;

import React from "react";
import { Box, Typography, Switch, Checkbox } from "@mui/material";

interface NotificationItemProps {
    icon: React.ReactNode;
    title: string;
    subtitle?: string;
    checked: boolean;
    onChange: () => void;
    disabled?: boolean;
    isSwitch?: boolean;
    user?: any;
}

export const NotificationItem = ({
    icon,
    title,
    subtitle,
    checked,
    onChange,
    disabled = false,
    isSwitch = false,
    user
}: NotificationItemProps) => {
    
    const isDisabled = isSwitch
        ? disabled
        : disabled ||
        (title === "WhatsApp" && !user?.phone) ||
        (title === "Email" && !user?.email);

    const warningStyle = subtitle === "No añadido" ? {
        color: "#856404",
        bgcolor: "#fff3cd", 
        px: 1,
        py: 0.5,
        borderRadius: 1,
        mt: 1 
    } : {};

    const isChecked = (title === "WhatsApp" && !user?.phone) || 
                     (title === "Email" && !user?.email) 
                     ? false 
                     : checked;

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1.5, px: 1, borderRadius: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {icon}
                <Box>
                    <Typography variant="body1">{title}</Typography>
                    {subtitle && (
                        <Typography variant="body2" sx={{ ...warningStyle }}>
                            {subtitle}
                        </Typography>
                    )}
                </Box>
            </Box>

            {isSwitch ? (
                <Switch
                    checked={isChecked}
                    onChange={onChange}
                    disabled={isDisabled}
                    sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                            color: '#4caf50',
                            '& + .MuiSwitch-track': {
                                backgroundColor: '#4caf50',
                            },
                        }
                    }}
                />
            ) : (
                <Checkbox
                    checked={isChecked}
                    onChange={onChange}
                    disabled={isDisabled}
                    sx={{
                        color: isChecked ? '#4caf50' : 'grey.500',
                        '&.Mui-checked': { color: '#4caf50' },
                        opacity: isDisabled ? 0.5 : 1
                    }}
                />
            )}
        </Box>
    );
};
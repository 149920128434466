import { Container, Paper, Typography, Box, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ModalDetaildPayment from "../ModalDetaildPayment";
import createNumberArray from "../../../shared/utils/createNumberArray";
import { MONTHS_IN_SPANISH } from "../../../constants/months-in-spanish";
import usePayments from "../hooks/usePayments";
import { useBuilding } from "../../building/hooks/useBuilding";
import formatPaymentPeriodDate from "../utils/formatPaymentPeriodDate";
import { Payment } from "../../../supabase/type/db-entities";

interface Data {
  id: number;
  created_at: string;
  payment_date: string;
  payment_method: string;
  status: string;
  amount: string;
  actions: string;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width?: number;
}

const columns: ColumnData[] = [
  {
    width: 150,
    label: "Fecha",
    dataKey: "created_at",
  },
  {
    width: 150,
    label: "Periodo",
    dataKey: "payment_date",
  },
  {
    width: 150,
    label: "Método de pago",
    dataKey: "payment_method",
  },
  {
    width: 150,
    label: "Estado",
    dataKey: "status",
  },
  {
    width: 100,
    label: "Valor",
    dataKey: "amount",
  },
  {
    width: 100,
    label: "Acciones",
    dataKey: "actions",
  },
];

type HistoricalPaymentsProps = {
  payments: Payment[];
  loading: boolean;
};
export default function HistoricalPayments({
  payments,
  loading,
}: HistoricalPaymentsProps) {
  const [openModalDetailsPayment, setOpenModalDetailsPayment] = useState(false);
  const [transactionIdSelect, setTransactionIdSelect] = useState<
    number | string
  >("");

  const VirtuosoTableComponents: TableComponents<Data> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer
        component={Paper}
        {...props}
        ref={ref}
        sx={{ borderRadius: 2, overflow: "hidden" }}
      />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: "separate", tableLayout: "fixed", minWidth: 700 }}
      />
    ),
    TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableHead {...props} ref={ref} />
    )),
    TableRow,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? "right" : "left"}
            style={{ width: column.width }}
            sx={{
              backgroundColor: "#E1E8FF",
              color: "#4a4a4a",
              borderBottom: "none",
              borderTop: "none",
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function rowContent(_index: number, row: Data) {
    return (
      <>
        {columns.map((column) => {
          let value: React.ReactNode = row[column.dataKey];

          if (column.dataKey === "payment_date") {
            value = formatPaymentPeriodDate(value as string);
          }

          if (column?.dataKey === "created_at") {
            value = new Date(value as string).toLocaleDateString();
          }
          if (column.dataKey === "payment_method") {
            value = (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <ReceiptLongIcon sx={{ fontSize: 18, color: "#4a4a4a" }} />
                {value}
              </Box>
            );
          }

          if (column.dataKey === "status") {
            let statusText = "";
            let bgColor = "";
            let textColor = "";
            
            switch(value) {
              case "pending":
                statusText = "Por Validar";
                bgColor = "#FEEBC8";
                textColor = "#D69E2E";
                break;
              case "verified":
                statusText = "Validado";
                bgColor = "#C6F6D5";
                textColor = "#2F855A";
                break;
              case "rejected":
                statusText = "Rechazado";
                bgColor = "#FED7D7";
                textColor = "#E53E3E";
                break;
              default:
                statusText = "Desconocido";
                bgColor = "#E2E8F0";
                textColor = "#4A5568";
            }
            
            value = (
              <Box
                sx={{
                  display: "inline-block",
                  px: 2,
                  py: 0.5,
                  backgroundColor: bgColor,
                  color: textColor,
                  borderRadius: 2,
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                }}
              >
                {statusText}
              </Box>
            );
          }

          if (column.dataKey === "amount") {
            value = `S/ ${value}`;
          }

          if (column.dataKey === "actions") {
            value = (
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Box
                  sx={{ cursor: "pointer", color: "#315CAC" }}
                  onClick={() => {
                    setOpenModalDetailsPayment(true);
                    setTransactionIdSelect(row?.id);
                  }}
                >
                  <RemoveRedEyeOutlinedIcon
                    sx={{ cursor: "pointer", color: "#315CAC" }}
                  />
                </Box>
                <ModalDetaildPayment
                  open={openModalDetailsPayment}
                  onClose={() => setOpenModalDetailsPayment(false)}
                  transactionId={transactionIdSelect}
                />
              </Box>
            );
          }

          return (
            <TableCell
              key={column.dataKey}
              align={column.numeric || false ? "right" : "left"}
              sx={{
                borderBottom: "1px solid #EDEDED",
                fontSize: "0.95rem",
                color: "#4a4a4a",
              }}
            >
              {value}
            </TableCell>
          );
        })}
      </>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: 2,
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ fontSize: "1.3rem", mb: 1, fontWeight: "bold" }}
        >
          Historial de pagos
        </Typography>
      </Box>

      <Paper
        style={{
          height: 400,
          width: "100%",
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        <TableVirtuoso
          data={loading ? createNumberArray(10) : payments}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={loading ? TableRowSkeleton : rowContent}
        />
      </Paper>
    </Box>
  );
}

const TableRowSkeleton = () =>
  columns.map((column) => (
    <TableCell
      key={column.dataKey}
      align={column.numeric || false ? "right" : "left"}
    >
      <Skeleton width={140} height={18} />
    </TableCell>
  ));

// src/features/invitation/components/VisitList.tsx

import { Box, Grid2 } from "@mui/material";
import VisitCard, { VisitCardSkeleton } from "./VisitCard";
import NoVisitsMessage from "./NoVisitsMessage";
import createNumberArray from "../../../shared/utils/createNumberArray";

const VisitList = ({
  visits = [],
  isActiveTab,
  onVisitClick,
}: {
  visits: any[];
  isActiveTab?: boolean;
  onVisitClick: (visitId: string) => void;
}) => {
  if (!Array.isArray(visits)) {
    return <NoVisitsMessage isActiveTab={isActiveTab} />;
  }

  return visits.length === 0 ? (
    <NoVisitsMessage isActiveTab={isActiveTab} />
  ) : (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {visits.map((visit) => (
        <VisitCard key={visit.id} visit={visit} onVisitClick={onVisitClick} />
      ))}
    </Box>
  );
};

export const VisitListSkeleton = () => (
  <Grid2 container spacing={2} direction="column">
    {createNumberArray(5).map((num) => (
      <Grid2 key={num}>
        <VisitCardSkeleton />
      </Grid2>
    ))}
  </Grid2>
);

export default VisitList;

// src/features/building/hooks/useBuilding.ts

import { useBuildingAnalytics } from "./useBuildingAnalytics";
import useGlobalStore from "../../../state/store";

export const useBuilding = () => {
  const setBuildingId = useGlobalStore((state) => state.setBuildingId);
  const buildingId = useGlobalStore((state) => state.buildingId);
  const setApartmentId = useGlobalStore((state) => state.setApartmentId);
  const apartmentId = useGlobalStore((state) => state.apartmentId);
  const setMaintenanceFees = useGlobalStore(
    (state) => state.setMaintenanceFees
  );
  const setTotalMaintenanceFees = useGlobalStore(
    (state) => state.setTotalMaintenanceFees
  );

  const { trackApartmentSelected } = useBuildingAnalytics();

  const selectApartment = async (apartmentId: string) => {
    setApartmentId(apartmentId);
    trackApartmentSelected(apartmentId, buildingId!);
  };

  const clearBuilding = () => {
    setBuildingId(null);
    setApartmentId(null);
    clearMaintenanceFees();
  };

  const clearMaintenanceFees = () => {
    setMaintenanceFees({ data: [], loading: false, error: null });
    setTotalMaintenanceFees({
      data: {
        totalAmount: null,
        issueDate: null,
        dueDate: null,
      },
      loading: false,
      error: null,
    });
  };

  return {
    buildingId,
    setBuildingId,
    apartmentId,
    setApartmentId,
    selectApartment,
    clearBuilding,
  };
};

import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import useGlobalStore from "../../../state/store";
import { invitationService } from "../services/invitationService";

function useVisits() {
  const setVisits = useGlobalStore((state) => state.setVisits);
  const visits = useGlobalStore((state) => state.visits);

  const hasCachedVisits = isValidNonEmptyArray(visits.data);

  const getVisitFormLocalStore = (visitId: string) =>
    visits.data.find((visit) => visit.id === visitId) ?? null;

  const getVisits = async () => {
    try {
      setVisits({ data: [], loading: true, error: null });
      const data = await invitationService.fetchUserVisits();

      setVisits({
        data: isValidNonEmptyArray(data) ? data : [],
        loading: false,
        error: null,
      });
    } catch (err) {
      console.error("Error fetching visits:", err);
      setVisits({
        data: [],
        loading: false,
        error: "Failed to fetch visits. Please try again later.",
      });
    }
  };

  const clearVisits = () => {
    setVisits({ data: [], loading: false, error: null });

    console.log("Visit cleared");
  };

  return {
    visits,
    setVisits,
    hasCachedVisits,
    getVisitFormLocalStore,
    getVisits,
    clearVisits,
  };
}

export default useVisits;

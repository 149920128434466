// src/storage/services/supabaseStorage.ts

import { supabase } from '../../supabase/client';
import { STORAGE_CONFIG } from '../config/constants';
import { validateFile, validateFiles } from '../utils/validation';
import { generateFileName } from '../utils/fileNaming';

interface UploadOptions {
  path: string;
}

interface UploadResult {
  url: string;
  path: string;
  type: string;
  size: number;
}

class SupabaseStorageService {
  private storage = supabase.storage;
  private bucketName: string;

  constructor(bucketName: string = STORAGE_CONFIG.BUCKET_NAME) {
    this.bucketName = bucketName;
  }


  private async ensureAuthenticated(): Promise<void> {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error || !session)
    {
      throw new Error('User must be authenticated to access storage');
    }
  }

  async uploadFile(file: File, { path }: UploadOptions): Promise<UploadResult> {
    try
    {
      await this.ensureAuthenticated();
      validateFile(file);

      const filePath = generateFileName(file, path);
      console.log('Generated file path:', filePath);

      const options = {
        cacheControl: '3600',
        upsert: true,
        contentType: file.type,
      };

      const { error: uploadError } = await this.storage
        .from(this.bucketName)
        .upload(filePath, file, options);

      if (uploadError) throw uploadError;

      const url = await this.getFileUrl(filePath);

      return {
        url,
        path: filePath,
        type: file.type,
        size: file.size,
      };
    } catch (error: any)
    {
      console.error('Error in uploadFile:', error);
      throw error;
    }
  }

  async uploadMultipleFiles(files: File[], { path }: UploadOptions): Promise<UploadResult[]> {
    try
    {
      await this.ensureAuthenticated();

      if (!files || files.length === 0) return [];

      validateFiles(files);

      const uploadPromises = files.map(file => this.uploadFile(file, { path }));
      const results = await Promise.all(uploadPromises);

      return results.filter(result => result !== null);
    } catch (error: any)
    {
      console.error('Error in uploadMultipleFiles:', error);
      throw error;
    }
  }

  async getFileUrl(filePath: string): Promise<string> {
    try
    {
      await this.ensureAuthenticated();

      const { data, error } = await this.storage
        .from(this.bucketName)
        .createSignedUrl(filePath, 3600);

      if (error) throw error;
      return data.signedUrl;
    } catch (error: any)
    {
      console.error('Error in getFileUrl:', error);
      throw error;
    }
  }

  async deleteFile(filePath: string): Promise<void> {
    try
    {
      await this.ensureAuthenticated();

      const { error } = await this.storage
        .from(this.bucketName)
        .remove([filePath]);

      if (error) throw error;
    } catch (error: any)
    {
      console.error('Error in deleteFile:', error);
      throw error;
    }
  }

  async deleteMultipleFiles(filePaths: string[]): Promise<void> {
    try
    {
      await this.ensureAuthenticated();

      if (!filePaths || filePaths.length === 0) return;

      const { error } = await this.storage
        .from(this.bucketName)
        .remove(filePaths);

      if (error) throw error;
    } catch (error: any)
    {
      console.error('Error in deleteMultipleFiles:', error);
      throw error;
    }
  }

  async moveFile(oldPath: string, newPath: string): Promise<void> {
    try
    {
      await this.ensureAuthenticated();

      const { error } = await this.storage
        .from(this.bucketName)
        .move(oldPath, newPath);

      if (error) throw error;
    } catch (error: any)
    {
      console.error('Error in moveFile:', error);
      throw error;
    }
  }

  async copyFile(sourcePath: string, destinationPath: string): Promise<void> {
    try
    {
      await this.ensureAuthenticated();

      const { error } = await this.storage
        .from(this.bucketName)
        .copy(sourcePath, destinationPath);

      if (error) throw error;
    } catch (error: any)
    {
      console.error('Error in copyFile:', error);
      throw error;
    }
  }

  async listFiles(prefix = ''): Promise<any[]> {
    try
    {
      await this.ensureAuthenticated();

      const { data, error } = await this.storage
        .from(this.bucketName)
        .list(prefix);

      if (error) throw error;
      return data || [];
    } catch (error: any)
    {
      console.error('Error in listFiles:', error);
      throw error;
    }
  }
}

export const supabaseStorage = new SupabaseStorageService();

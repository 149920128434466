type Primitive = string | number | boolean | symbol | bigint | undefined | null;
type Referential = object | Function | Array<any>;

/**
 * Validates if a value is truthy, handling both primitive and referential types
 * @param value - The value to check
 * @returns boolean - True if the value is considered truthy
 */
export default function isTruthy<T extends Primitive | Referential>(
  value: T
): boolean {
  // Handle primitive types
  if (isPrimitive(value)) {
    // Explicitly check for falsy primitive values
    return (
      value !== false &&
      value !== 0 &&
      value !== 0n &&
      value !== "" &&
      value !== null &&
      value !== undefined
    );
  }

  // Handle referential types
  if (isReferential(value)) {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (value instanceof Map || value instanceof Set) {
      return value.size > 0;
    }
    if (value instanceof Date) {
      return !isNaN(value.getTime());
    }
    if (typeof value === "function") {
      return true; // Functions are always truthy
    }
    // For plain objects, check if they have any own properties
    return Object.keys(value).length > 0;
  }

  return false;
}

/**
 * Type guard to check if a value is a primitive type
 */
function isPrimitive(value: unknown): value is Primitive {
  return (
    value === null || (typeof value !== "object" && typeof value !== "function")
  );
}

/**
 * Type guard to check if a value is a referential type
 */
function isReferential(value: unknown): value is Referential {
  return typeof value === "object" || typeof value === "function";
}

export { isTruthy, isPrimitive, isReferential };

import { useState, useEffect } from "react";
import { Skeleton, Typography, Box, Modal } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { truncateFilename } from "../../../shared/utils/truncateFilename";
import { supabaseStorage } from "../../../storage/services/supabaseStorage";

export default function ImageViewerVaucher({
  pathImage,
  nameImage,
  imageUrl,
  onDelete,
}: {
  pathImage?: string;
  nameImage: string;
  imageUrl?: string;
  onDelete?: () => void;
}) {
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [signedUrl, setSignedUrl] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSignedUrl = async () => {
    
      try {
        if (imageUrl) {
          setSignedUrl(imageUrl);
          setLoading(false);
          return;
        }

        if (pathImage) {
          setLoading(true);
          const imageUrl = await supabaseStorage.getFileUrl(pathImage);
          setSignedUrl(imageUrl);
          setError(null);
        }
      } catch (error) {
        console.error("Error getting signed URL:", error);
        setError(error instanceof Error ? error.message : "Unknown error");
      } finally {
        setLoading(false);
      }
    };

    fetchSignedUrl();
  }, [pathImage, imageUrl]);

  const handleCloseModalPreview = () => setOpenModalPreview(false);

  const handleOpenModalPreview = () => setOpenModalPreview(true);

  const ModalViewImage = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      height: "AUTO",
      bgcolor: "background.paper",
      border: "2px solid #CCE1FF",
      boxShadow: 24,
      p: 2,
      display: "flex",
      justifyContent: "center",
      borderRadius: 2,
    };

    return (
      <Modal
        open={openModalPreview}
        onClose={handleCloseModalPreview}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button
            onClick={handleCloseModalPreview}
            style={{
              position: "absolute",
              right: 10,
              backgroundColor: "#fff",
              border: "none",
              cursor: "pointer",
            }}
          >
            Cerrar
          </button>
          {signedUrl && (
            <img src={signedUrl} alt="Domus" style={{ width: "60%" }} />
          )}
        </Box>
      </Modal>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        flex: 1,
        mx: 4,
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {loading ? (
          <Skeleton variant="rectangular" width={50} height={80} />
        ) : error ? (
          <Typography variant="caption" color="error">
            Error loading image
          </Typography>
        ) : (
          <img src={signedUrl} alt="Domus" style={{ height: "50px" }} />
        )}
        {nameImage ? (
          <Typography
            variant="body1"
            sx={{ color: "#5D5E61", fontSize: "0.9rem", mx: 2 }}
          >
            {truncateFilename(nameImage, 20)}
          </Typography>
        ) : (
          <Skeleton
            variant="rectangular"
            width={300}
            height={10}
            sx={{ mx: 2 }}
          />
        )}
      </Box>

      <Box
        sx={{
          borderLeft: 1,
          px: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderLeftColor: "#CCE1FF",
        }}
      >
        <Box>
          <RemoveRedEyeOutlinedIcon
            sx={{ mx: 1, color: "#315CAC", cursor: "pointer" }}
            onClick={handleOpenModalPreview}
          />
          <ModalViewImage />
        </Box>

        {onDelete && (
          <Box onClick={onDelete}>
            <DeleteOutlineOutlinedIcon
              sx={{ color: "#315CAC", cursor: "pointer" }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

import { useEffect, useState } from "react";
import {
    Box, Typography, Divider, Paper, CircularProgress
} from "@mui/material";
import {
    WhatsApp as WhatsAppIcon,
    Email as EmailIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon,
    NotificationsActive as NotificationsActiveIcon,
    NotificationsOff as NotificationsOffIcon
} from "@mui/icons-material";
import { authService } from "../../auth/services/authService";
import { NotificationItem } from "./NotificationItem";

interface NotificationPreferences {
    emailEnabled: boolean;
    whatsappEnabled: boolean;
    maintenanceNotifications: boolean;
}

export default function Notifications({ user }: { user: any }) {
    const [preferences, setPreferences] = useState<NotificationPreferences>({
        emailEnabled: false,
        whatsappEnabled: false,
        maintenanceNotifications: false
    });
    const [allEnabled, setAllEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (user?.id) {
            loadPreferences();
        }
    }, [user]);

    const loadPreferences = async (): Promise<void> => {
        try { 
            const data = await authService.getUserNotificationPreferences(user.id);

            const newPrefs = {
                emailEnabled: user?.email ? data.email_enabled : false,
                whatsappEnabled: user?.phone ? data.whatsapp_enabled : false,
                maintenanceNotifications: data.maintenance_notifications
            };
            
            setPreferences(newPrefs);
            
            const validChannelsActive = 
                (!user?.email || newPrefs.emailEnabled) && 
                (!user?.phone || newPrefs.whatsappEnabled) && 
                newPrefs.maintenanceNotifications;
                
            setAllEnabled(validChannelsActive);
        } catch (error) {
            console.error("Error loading preferences:", error);
        }  
    };

    const updatePreference = async (key: keyof NotificationPreferences, value: boolean): Promise<void> => {
        if ((key === "emailEnabled" && !user?.email) || 
            (key === "whatsappEnabled" && !user?.phone)) {
            return;
        }

        setPreferences(prev => ({ ...prev, [key]: value }));

        const keyMap: Record<keyof NotificationPreferences, string> = {
            emailEnabled: "email_enabled",
            whatsappEnabled: "whatsapp_enabled",
            maintenanceNotifications: "maintenance_notifications"
        };

        try {
            await authService.updateSingleNotificationPreference(
                user.id,
                keyMap[key],
                value
            );
            
            const updatedPrefs = { ...preferences, [key]: value };
            const allActive = 
                (!user?.email || updatedPrefs.emailEnabled) && 
                (!user?.phone || updatedPrefs.whatsappEnabled) && 
                updatedPrefs.maintenanceNotifications;
                
            setAllEnabled(allActive);
        } catch (error) {
            setPreferences(prev => ({ ...prev, [key]: !value }));
        }
    };

    const toggleAllNotifications = async (value: boolean): Promise<void> => {
        setAllEnabled(value);

        const newPrefs = {
            emailEnabled: user?.email ? value : false,
            whatsappEnabled: user?.phone ? value : false,
            maintenanceNotifications: value
        };
        
        setPreferences(newPrefs);

        try {
            await authService.updateAllNotificationPreferences(user.id, value);
        } catch (error) { 
            console.error("Error updating all notifications:", error);
            setAllEnabled(!value);
            loadPreferences();
        }
    };

  
    return (
        <Paper variant="outlined" sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <NotificationItem
                icon={allEnabled
                    ? <NotificationsActiveIcon sx={{ color: '#4caf50' }} />
                    : <NotificationsOffIcon sx={{ color: 'grey.500' }} />
                }
                title="Todas las notificaciones"
                checked={allEnabled}
                onChange={() => toggleAllNotifications(!allEnabled)}
                isSwitch={true}
                user={user}
            />

            <Box sx={{ px: 2, pt: 2, pb: 1 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 1 }}>
                    Canal de comunicación
                </Typography>

                <NotificationItem
                    icon={<WhatsAppIcon sx={{ color: '#25D366' }} />}
                    title="WhatsApp"
                    subtitle={user?.phone || "No añadido"}
                    checked={preferences.whatsappEnabled}
                    onChange={() => updatePreference("whatsappEnabled", !preferences.whatsappEnabled)}
                    user={user}
                />

                <NotificationItem
                    icon={<EmailIcon sx={{ color: '#9E9E9E' }} />}
                    title="Email"
                    subtitle={user?.email || "No añadido"}
                    checked={preferences.emailEnabled}
                    onChange={() => updatePreference("emailEnabled", !preferences.emailEnabled)}
                    user={user}
                />

                <Divider sx={{ my: 1 }} />

                <NotificationItem
                    icon={<AccountBalanceWalletIcon sx={{ color: '#757575' }} />}
                    title="Cuotas de Mantenimiento"
                    checked={preferences.maintenanceNotifications}
                    onChange={() => updatePreference("maintenanceNotifications", !preferences.maintenanceNotifications)}
                    user={user}
                />

                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 5, mb: 2 }}
                >
                    Te enviaremos recordatorios antes de tu fecha de pago y avisos después del vencimiento si es necesario.
                </Typography>
            </Box>
        </Paper>
    );
}
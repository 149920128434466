import { create } from "zustand";

import { authService } from "../features/auth/services/authService";
import { supabase } from "../supabase/client";
import { BankAccount, Charge, Payment } from "../supabase/type/db-entities";
import { Visit } from "../features/invitation/types";
import { Incident } from "../features/incidents/types";
import { SupabaseAuthClient } from "@supabase/supabase-js/dist/module/lib/SupabaseAuthClient";
import { Session } from "@supabase/auth-js";
import hasKeys from "../shared/utils/hasKey";
import { isValidNonEmptyArray } from "../shared/utils/isValidNonEmptyArray";

export interface User {
  id: string;
  email: string;
  [key: string]: any;
}

export type RequestData<T> = {
  error: string | null;
  data: T;
  loading: boolean;
};

export type TotalMaintenanceFees = {
  totalAmount: number | null;
  issueDate: string | null;
  dueDate: string | null;
};

export type State = {
  // Session
  session: Session | null;
  setSession: (session: Session | null) => void;
  user: any;
  loading: boolean;
  error: any;
  maintenanceFees: RequestData<Charge[]>;
  setMaintenanceFees: (maintenanceFees: RequestData<Charge[]>) => void;
  totalMaintenanceFees: RequestData<TotalMaintenanceFees>;
  setTotalMaintenanceFees: (
    totalMaintenanceFees: RequestData<TotalMaintenanceFees>
  ) => void;

  payments: RequestData<Payment[]>;
  setPayments: (payments: RequestData<Payment[]>) => void;

  visits: RequestData<Visit[]>;
  setVisits: (visits: RequestData<Visit[]>) => void;

  bankAccounts: RequestData<BankAccount[]>;
  setBankAccounts: (bankAccounts: RequestData<BankAccount[]>) => void;

  incidents: RequestData<Incident[]>;
  setIncidents: (incidents: RequestData<Incident[]>) => void;
  setUser: (user: any) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: any) => void;

  invitationToken: string | null;
  invitationId: string | null;
  buildingId: string | null;
  apartmentId: string | null;
  setInvitationToken: (token: string | null) => void;
  setInvitationId: (id: string | null) => void;
  setBuildingId: (id: string | null) => void;
  setApartmentId: (id: string | null) => void;

  fetchUserProfile: (authUser: any) => Promise<void>;
  initializeUser: () => Promise<void>;
  clearContext: () => void;
};

export const useGlobalStore = create<State>((set, get) => ({
  //Session
  session: null,
  setSession: (session: Session | null) => set({ session }),

  // User State
  user: null,
  loading: false,
  error: null,

  // App State
  invitationToken: null,
  invitationId: null,
  buildingId: null,
  apartmentId: null,

  // Maintenance Fees

  maintenanceFees: {
    error: null,
    data: [],
    loading: false,
  },

  setMaintenanceFees: (maintenanceFees) => set({ maintenanceFees }),

  totalMaintenanceFees: {
    error: null,
    data: {
      totalAmount: null,
      issueDate: null,
      dueDate: null,
    },
    loading: false,
  },
  setTotalMaintenanceFees: (totalMaintenanceFees) =>
    set({ totalMaintenanceFees }),

  // payments
  payments: { data: [], loading: false, error: null },
  setPayments: (payments) => set({ payments }),

  // bank account

  bankAccounts: {
    error: null,
    data: [],
    loading: false,
  },

  setBankAccounts: (bankAccounts) => {
    set({ bankAccounts });
  },

  // Visits
  visits: {
    error: null,
    data: [],
    loading: false,
  },
  setVisits: (visits) => set({ visits }),

  // Incidents
  incidents: {
    error: null,
    data: [],
    loading: false,
  },
  setIncidents: (incidents) => set({ incidents }),

  // User Actions
  setUser: (user) => set({ user }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),

  fetchUserProfile: async (authUser: Session["user"]) => {
    if (!authUser) {
      set({ user: null, loading: false });
      return;
    }

    try {
      set({ error: null });
      const userProfile = await authService.getUserProfile(authUser.id);

      const residenceStructure = await authService.getUserResidenceStructure(
        userProfile.id
      );

      const mergedUser = {
        ...authUser,
        ...userProfile,
        buildings: residenceStructure,
      };

      if (hasKeys(mergedUser, ["buildings", "building"])) {
        if (isValidNonEmptyArray(mergedUser.buildings)) {
          const defaultBuilding = mergedUser.buildings[0];

          console.log(defaultBuilding);

          set({ buildingId: defaultBuilding.id });

          if (isValidNonEmptyArray(defaultBuilding.apartments)) {
            set({ apartmentId: defaultBuilding.apartments[0]?.id });
          }
        }
      }

      set({ user: mergedUser });
    } catch (error) {
      set({ error, user: authUser });
    } finally {
      set({ loading: false });
    }
  },

  initializeUser: async () => {
    const session = get().session;

    if (session) {
      await get().fetchUserProfile(session.user);
    } else {
      set({ user: null, loading: false });
    }
  },

  // App Actions
  setInvitationToken: (token) => set({ invitationToken: token }),
  setInvitationId: (id) => set({ invitationId: id }),
  setBuildingId: (id) => set({ buildingId: id }),
  setApartmentId: (id) => set({ apartmentId: id }),

  clearContext: () =>
    set({ invitationToken: null, buildingId: null, apartmentId: null }),
}));

export default useGlobalStore;

// src/features/maintenance/hooks/useMaintenanceFees2.ts

import { useEffect } from "react";
import { secureApi } from "../../../supabase/secureApi";
import { Charge, Payment } from "../../../supabase/type/db-entities";
import useGlobalStore from "../../../state/store";
import findOldestInvoice from "../utils/findOldestInvoice";
import usePayments from "./usePayments";

function useMaintenanceFees2() {
  const maintenanceFees = useGlobalStore((state) => state.maintenanceFees);
  const setMaintenanceFees = useGlobalStore((state) => state.setMaintenanceFees);
  const totalMaintenanceFees = useGlobalStore((state) => state.totalMaintenanceFees);
  const setTotalMaintenanceFees = useGlobalStore(
    (state) => state.setTotalMaintenanceFees
  );

  const { payments, getPayments } = usePayments();
/** Discard refetch when we already have total maintenance fees in global store */
    const hasCachedTotalMaintainFees = Object.values(
    totalMaintenanceFees.data
  ).every((value) => value !== null);
  const getMaintenanceFees = async ({
    apartmentId,
    buildingId,
  }: {
    apartmentId: string;
    buildingId: string;
  }) => {
    if (!apartmentId) return;
    try {
      // Inicializar estados
      setTotalMaintenanceFees({
        data: {
          totalAmount: null,
          issueDate: null,
          dueDate: null,
        },
        loading: true,
        error: null,
      });

      setMaintenanceFees({
        data: [],
        loading: true,
        error: null,
      });

      // Obtener los cargos del apartamento
      const charges: Charge[] = await secureApi.get("charges", {
        select: "*",
        filter: { apartment_id: apartmentId },
      });

      // Guardar los cargos en el estado global
      setMaintenanceFees({
        data: charges,
        loading: false,
        error: null,
      });

      // Obtener los pagos del apartamento
      await getPayments({ buildingId, apartmentId });

      // Los pagos se almacenan en 'payments.data'

    } catch (error) {
      setTotalMaintenanceFees({
        data: {
          totalAmount: null,
          issueDate: null,
          dueDate: null,
        },
        loading: false,
        error: "Fallo al obtener detalles de las cuotas de mantenimiento",
      });

      setMaintenanceFees({
        data: [],
        loading: false,
        error: "Fallo al obtener los cargos",
      });
      console.error("Fallo al obtener los datos:", error);
    }
  };

  useEffect(() => {
    // Verificar que los cargos y pagos han sido cargados
    if (
      !maintenanceFees.loading &&
      !payments.loading &&
      maintenanceFees.data.length > 0
    ) {
      // Calcular el total de cargos
      const totalCharges = maintenanceFees.data.reduce(
        (accumulator, charge) => accumulator + Number(charge.total_amount),
        0
      );

      // Calcular el total de pagos
      const totalPayments = payments.data.reduce(
        (accumulator, payment) => accumulator + Number(payment.amount),
        0
      );

      // Calcular el monto pendiente
      const totalAmount = totalCharges - totalPayments;

      // Encontrar el cargo más antiguo
      const oldestCharge = findOldestInvoice(maintenanceFees.data);

      const issueDate = oldestCharge?.issue_date || null;
      const dueDate = oldestCharge?.due_date || null;

      setTotalMaintenanceFees({
        data: {
          totalAmount,
          issueDate,
          dueDate,
        },
        loading: false,
        error: null,
      });
    }
  }, [maintenanceFees.loading, payments.loading, maintenanceFees.data, payments.data]);

  const maintenanceFeesNotFound =
  !totalMaintenanceFees.loading && !hasCachedTotalMaintainFees;


  return {
    totalMaintenanceFees,
    getMaintenanceFees,
    maintenanceFeesNotFound,
    maintenanceFees,
    hasCachedTotalMaintainFees
  };
}

export default useMaintenanceFees2;

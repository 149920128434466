// src/features/auth/pages/Register.tsx

import React, { useState, useEffect } from "react";
import { Box, Snackbar } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import EmailInput from "../components/EmailInput";
import EmailConfirmation from "../components/EmailConfirmation";
import UserInfoInput from "../components/UserInfoInput";
import { useAuthAnalytics } from "../hooks/useAuthAnalytics";
import { EVENT_TYPES } from "../../../analytics/config/events";
import { useLocation, useNavigate } from "react-router-dom";
import getSearchParams from "../../../shared/utils/getSearchParams";
import { ROUTES } from "../../../config/routes";

const Register = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  // Removed password state variable as it's handled in EmailInput
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    dni: "",
    phone: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [stepStartTime, setStepStartTime] = useState(Date.now());
  const [registrationContext, setRegistrationContext] = useState("normal");

  const { getParams } = useNavigation();
  const { trackRegisterStep } = useAuthAnalytics();
  const { signUpWithOtp, verifyOtp, completeRegistration } = useAuth();

  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectTo = getSearchParams(search, "redirect_to");

  useEffect(() => {
    setStepStartTime(Date.now());
  }, [step]);

  const trackStepCompletion = (
    eventName: any,
    additionalProperties: any = {}
  ) => {
    const timeSpent = Date.now() - stepStartTime;
    trackRegisterStep(eventName, {
      timeSpent,
      registrationContext,
      ...additionalProperties,
    });
  };

  const handleEmailSubmit = async (
    submittedEmail: string,
    submittedPassword: string
  ) => {
    try {
      const result = await signUpWithOtp(submittedEmail, submittedPassword);
      if (result.user) {
        setEmail(submittedEmail);
        setStep(2);
        setSnackbarMessage(
          "Se ha enviado un código de confirmación a tu correo"
        );
        trackStepCompletion(EVENT_TYPES.REGISTER_EMAIL_SUBMITTED, {
          email: submittedEmail,
        });
      } else {
        setSnackbarMessage("Error en el proceso de registro");
        trackRegisterStep(EVENT_TYPES.REGISTER_ERROR, {
          step: "email_submission",
          error: "Unknown error",
          registrationContext,
          email: submittedEmail,
        });
      }
      setOpenSnackbar(true);
    } catch (error: any) {
      console.error("Signup error:", error);
      setSnackbarMessage(
        error.message || "Error al enviar el código de confirmación"
      );
      setOpenSnackbar(true);
      trackRegisterStep(EVENT_TYPES.REGISTER_ERROR, {
        step: "email_submission",
        error: error.message,
        registrationContext,
        email: submittedEmail,
      });
    }
  };

  const handleEmailConfirmation = async (token: string) => {
    try {
      await verifyOtp(email, token);
      setStep(3);
      trackStepCompletion(EVENT_TYPES.REGISTER_EMAIL_CONFIRMED, { email });
    } catch (error: any) {
      console.error("OTP verification error:", error);
      setSnackbarMessage(error.message || "Error al verificar el código");
      setOpenSnackbar(true);
      trackRegisterStep(EVENT_TYPES.REGISTER_ERROR, {
        step: "email_confirmation",
        error: error.message,
        registrationContext,
        email,
      });
    }
  };
  const handleUserInfoSubmit = async (info: any) => {
    try {
      const completeInfo = { ...userInfo, ...info };
      setUserInfo(completeInfo);
      // Complete registration
      const updatedProfile = await completeRegistration(email, completeInfo);

      trackStepCompletion(EVENT_TYPES.REGISTER_COMPLETED, {
        userId: updatedProfile.id,
        registrationContext,
      });

      setSnackbarMessage("Registro completado exitosamente");
      navigate(redirectTo ? redirectTo : ROUTES.SUMMARY);
      setOpenSnackbar(true);
    } catch (error: any) {
      console.error("Registration completion error:", error);
      setSnackbarMessage(error.message || "Error al completar el registro");
      setOpenSnackbar(true);
      trackRegisterStep(EVENT_TYPES.REGISTER_ERROR, {
        step: "registration_completion",
        error: error.message,
        registrationContext,
        email,
      });
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", p: 2 }}>
      {step === 1 && <EmailInput onSubmit={handleEmailSubmit} />}
      {step === 2 && (
        <EmailConfirmation
          email={email}
          onConfirmed={handleEmailConfirmation}
          onBack={() => setStep(1)}
        />
      )}
      {step === 3 && (
        <UserInfoInput
          onSubmit={handleUserInfoSubmit}
          initialValues={userInfo}
        />
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default Register;

export function truncateFilename(filename: string, maxLength = 10) {
  if (filename.length <= maxLength) {
    return filename; // No need to truncate
  }

  const parts = filename.split(".");
  const ext = parts.pop(); // Remove and get the extension
  const name = parts.join("."); // Join back if there were multiple dots in the name

  if (name.length + ext?.length + 1 <= maxLength) {
    return filename;
  }

  const truncatedName = "..." + name.slice(-(maxLength - 4 - ext.length)); // -4 for "..." and "."
  return truncatedName + "." + ext;
}

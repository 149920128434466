import { Box, Typography, Button, Alert } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { supabase } from "../../supabase/client";
import UploadVoucher from "./components/UploadVaucheImage";
import MaintenanceFeeSummary from "./components/MaintenanceFeeSummary";

import { useBuilding } from "../building/hooks/useBuilding";

import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useMaintenanceFees from "./hooks/useMaintenanceFees";
import findOldestInvoice from "./utils/findOldestInvoice";
import { ROUTES } from "../../config/routes";
import { isValidNonEmptyArray } from "../../shared/utils/isValidNonEmptyArray";
import usePayments from "./hooks/usePayments";
import { useNavigation } from "../../navigation/hooks/useNavigation";
import {
  generatePaymentReceiptMissingFieldError,
  isPaymentReceiptValid,
} from "./validations";
import isTruthy from "../../shared/utils/isTruthy";
import pick from "../../shared/utils/pick";
import { useMaintenanceFeesAnalytics } from "./hooks/useMaintenanceFeesAnalytics";
import useUser from "../account/hooks/useUser";

const initialVoucher = {
  imageBase64: "",
  amount: 0,
  reference_number: "",
  bank_name: "",
  notes: "",
  payment_method: "",
  payment_date: "",
  evidence_url: "",
  evidence_path: "",
};

export default function RecordMaintenancePayment() {
  const [voucher, setVoucher] = useState(initialVoucher);

  console.log({ voucher });

  const [imageProcessingCompleted, setImageProcessingCompleted] =
    useState(false);
  const { buildingId, apartmentId } = useBuilding();
  const navigate = useNavigate();
  const { maintenanceFees, hasCachedTotalMaintainFees } = useMaintenanceFees();
  const { trackPaymentCompleted } = useMaintenanceFeesAnalytics();
  const { search } = useLocation();
  const  {user} = useUser();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);
  const searchParams = new URLSearchParams(search);

  const backPath = searchParams.get("from") ?? "../";

  const { getPayments } = usePayments();

  const { getMaintenanceFees } = useMaintenanceFees();
  const oldestCharge = findOldestInvoice(maintenanceFees.data);
  
  const requiredFields = pick(voucher, [
    "amount",
    "reference_number",
    "bank_name",
    "notes",
    "payment_method",
    "payment_date",
    "evidence_url",
    "evidence_path",
  ]);
  const isAllFieldsValid = isPaymentReceiptValid(requiredFields);

  const shouldRegisterVoucher =
    isTruthy(oldestCharge) && !error && !loading && isAllFieldsValid;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
  };

  const sendPayment = async () => {
    if (!oldestCharge) return;
    const values = {
      ...requiredFields,
      building_id: buildingId,
      apartment_id: apartmentId,
    };

    if (!isPaymentReceiptValid(requiredFields)) {
      const validationError =
        generatePaymentReceiptMissingFieldError(requiredFields);
      return setError(validationError);
    }

    try {
      setLoading(true);
      const { data, error } = await supabase
        .from("payments")
        .insert([values])
        .select();

      if (error) {
        console.error(error);

        setError("Algo salió mal al registrar el voucher de pago");

        return setLoading(false);
      }

      trackPaymentCompleted({
        userId: user?.id!,
        buildingId: buildingId!,
        apartmentId: apartmentId!,
        amount:  voucher.amount,
        paymentMethod: voucher.payment_method,
        transactionId:  data[0]?.id,
      });

      if (data) {
        const { data: paymentAllocations, error: paymentAllocationError } =
          await supabase
            .from("payment_allocations")
            .insert([
              {
                payment_id: data[0]?.id,
                allocated_amount: voucher.amount,
                charge_id: oldestCharge.id,
              },
            ])
            .select();

        setLoading(false);

        if (paymentAllocationError) {
          return setError("Algo salió mal al registrar el voucher de pago");
        }

        if (buildingId && apartmentId) {
          await Promise.all([
            getPayments({ buildingId, apartmentId }),
            getMaintenanceFees({ apartmentId }),
          ]);

          navigate(ROUTES.MAINTENANCE);
        }
      }
    } catch (error) {
      console.error("Failed to fetch building details:", error);

      setError("Algo salió mal al registrar el voucher de pago");
    }
  };

  useEffect(() => {
    if (!hasCachedTotalMaintainFees) {
      if (buildingId && apartmentId) {
        getMaintenanceFees({ apartmentId });
      }
    }
  }, []);

  const invalidVoucherError =
    generatePaymentReceiptMissingFieldError(requiredFields);

  return (
    <Box sx={style}>
      <Box
        sx={{
          borderBottom: 1,
          borderBottomColor: "#f1f0f4",
          borderBottomWidth: 1,
          height: "8vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          px: 6,
        }}
      >
        <img src={"/images/domus.png"} alt="Domus" style={{ height: "50px" }} />

        <Link to={backPath}>
          <button
            style={{
              border: "none",
              backgroundColor: "#fff",
              cursor: "pointer",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Cancelar X</Typography>
          </button>
        </Link>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 1, p: 6, overflowY: "scroll", height: "90vh", px: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 4 }}>
            <Link to={backPath}>
              <Button variant="text">
                <ArrowBackIcon
                  sx={{ fontSize: "1.5rem", cursor: "pointer", color: "#000" }}
                />
              </Button>
            </Link>

            <Box>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
              >
                Registro pago
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
              >
                Cuota de Mantenimiento
              </Typography>
            </Box>
          </Box>

          <UploadVoucher
            buildingId={buildingId}
            onImageProcessingStart={() => {
              setError("");
              setImageProcessingCompleted(false);
            }}
            onImageProcessingComplete={(data) => {
              setVoucher(data);
              setImageProcessingCompleted(true);
            }}
            onImageProcessingFail={(error) => {
              setImageProcessingCompleted(false);
              setError(error);
            }}
            onDeleteImageUpload={() => {
              setImageProcessingCompleted(false);
              setVoucher(initialVoucher);
              setError("");
            }}
          />

          {isTruthy(invalidVoucherError) &&
            imageProcessingCompleted &&
            !isAllFieldsValid && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {invalidVoucherError}
              </Alert>
            )}

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Button
            onClick={sendPayment}
            variant="contained"
            sx={{ width: "100%" }}
            disabled={!shouldRegisterVoucher}
          >
            {" "}
            Enviar
          </Button>
        </Box>

        <Box sx={{ backgroundColor: "#f4f8fa", flex: 1, p: 4 }}>
          <MaintenanceFeeSummary maintenanceFees={maintenanceFees.data} />
        </Box>
      </Box>
    </Box>
  );
}

import { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import useUser from "../hooks/useUser"; 
import General from "../components/General";
import Notifications from "../components/Notifications";

export default function Profile() {
    const { user, setUser } = useUser();
    const [tabIndex, setTabIndex] = useState<number>(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return (
        <Box sx={{ maxWidth: 600, mx: "auto", display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>Mi cuenta</Typography>

            <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="General" />
                <Tab label="Notificaciones" />
            </Tabs>

            {tabIndex === 0 && <General user={user} setUser={setUser} />}
            {tabIndex === 1 && <Notifications user={user} />}
        </Box>
    );
} 
import { useEffect } from "react";
import useIncidents from "../../incidents/hooks/useIncidents";
import useMaintenanceFees from "../../maintenance/hooks/useMaintenanceFees";
import usePayments from "../../maintenance/hooks/usePayments";
import { useBuilding } from "./useBuilding";
import useBankAccounts from "../../maintenance/hooks/useBankAccounts";

function useGetBuildingData() {
  const { getPayments, hasCachedPayments } = usePayments();
  const { getMaintenanceFees, hasCachedTotalMaintainFees } =
    useMaintenanceFees();
  const { getIncidents, hasCachedIncidents } = useIncidents();

  const { buildingId: currentBuildingId, apartmentId: currentApartmentId } =
    useBuilding();

  const { getBankAccount } = useBankAccounts();

  /**  Get fresh new data  when new building  selected */
  const getBuildingAssociatedData = async ({
    buildingId,
    apartmentId,
  }: {
    buildingId: string;
    apartmentId: string;
  }) => {
    if (buildingId && apartmentId) {
      /** We need to force  update for all data to override previous building cached data */

      Promise.all([
        getMaintenanceFees({ apartmentId }),
        getIncidents({ buildingId }),
        getPayments({ apartmentId, buildingId }),
        getBankAccount({ buildingId }),
      ]);
    }
  };

  const hasAllBuildingDataCached = [
    hasCachedIncidents,
    hasCachedPayments,
    hasCachedTotalMaintainFees,
  ].every(Boolean);

  return { getBuildingAssociatedData, hasAllBuildingDataCached };
}

export default useGetBuildingData;

// src/shared/components/ProfileMenu.tsx

import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Person } from "@mui/icons-material"; // Changed to Person icon
import { useNavigate } from "react-router-dom";
import { authService } from "../../features/auth/services/authService";
import { useAuth } from "../../features/auth/hooks/useAuth";
import { ROUTES } from "../../config/routes";

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const { logout } = useAuth();

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      logout();
    } catch (error) {
      //   console.error('Logout failed:', error);
      // You might want to show an error message to the user here
    }
    handleClose();
  };

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="primary"
      >
        <Person /> {/* Changed to Person icon */}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem> */}
        <MenuItem  onClick={() => navigate(ROUTES.PROFILE)}>Configuraciones</MenuItem>
        <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;

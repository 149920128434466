/**
 * Pick specified keys from an object
 * @param obj Source object
 * @param keys Keys to extract
 * @returns New object with only selected keys
 */

type Obj = Record<string, any>;
export default function pick<T extends Obj, K extends keyof T>(
  obj: T,
  keys: K[]
): Pick<T, K> {
  return keys.reduce((result, key) => {
    if (obj.hasOwnProperty(key)) {
      result[key] = obj[key];
    }
    return result;
  }, {} as Pick<T, K>);
}

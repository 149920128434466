import { useState } from "react";
import isValidNonEmptyObject from "../../../shared/utils/isValidNonEmptyObject";
import useGlobalStore from "../../../state/store";
import { secureApi } from "../../../supabase/secureApi";
import {
  BankAccount,
  BuildingFeesSetting,
} from "../../../supabase/type/db-entities";

import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";

function useBankAccounts() {
  const bankAccounts = useGlobalStore((state) => state.bankAccounts);
  const setBankAccounts = useGlobalStore((state) => state.setBankAccounts);

  const [hasLoadedBankAccounts, setHasLoadedBankAccounts] = useState(false);

  const hasCachedBankAccounts = isValidNonEmptyObject(bankAccounts.data);
  const getBankAccount = async ({ buildingId }: { buildingId: string }) => {
    if (buildingId) {
      try {
        setBankAccounts({
          data: [],
          loading: true,
          error: null,
        });
        const buildingFeeSettings: BuildingFeesSetting = await secureApi.get(
          "building_fee_settings",
          {
            select: "*",
            filter: { building_id: buildingId },

            single: true,
          }
        );

        setHasLoadedBankAccounts(true);

        if (buildingFeeSettings?.bank_accounts) {
          if (isValidNonEmptyObject(buildingFeeSettings.bank_accounts)) {
            const { accounts } = buildingFeeSettings.bank_accounts as { accounts: BankAccount[] };
            setBankAccounts({
              data: accounts,
              loading: false,
              error: null,
            });
          }
        }
      } catch (error) {
        setBankAccounts({
          data: [],
          loading: false,
          error: "Failed to fetch building details",
        });

        console.error("Failed to fetch building details:", error);
      }
    }
  };

  return {
    getBankAccount,
    bankAccounts,
    hasCachedBankAccounts,
    hasLoadedBankAccounts,
    bankAccountsNotFound: !isValidNonEmptyArray(bankAccounts.data),
  };
}

export default useBankAccounts;

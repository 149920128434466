// src/navigation/components/PrivateRoute.ts

import { useEffect, useState } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import { ROUTES } from "../../config/routes";

import useSession from "../../features/auth/hooks/useSession";
import useUser from "../../features/account/hooks/useUser";
import hasKeys from "../../shared/utils/hasKey";

const PrivateRoute = () => {
  const location = useLocation();

  const { isAuthenticated, authenticating } = useSession();

  const { getUserProfile, synchingUser } = useUser();

  useEffect(() => {
    if (isAuthenticated) {
      getUserProfile();
    }
  }, [isAuthenticated]);

  if (authenticating || synchingUser) {
    return <LoadingSpinner />;
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />
  );
};

const AuthRoute = ({ children }: any) => {
  const { isAuthenticated, authenticating } = useSession();
  const location = useLocation(); // added to check current route

  if (authenticating) {
    return <LoadingSpinner />;
  }

  // Do not redirect if on the registration page
  if (isAuthenticated && location.pathname !== ROUTES.REGISTER) {
    return <Navigate to={ROUTES.SUMMARY} replace />;
  }

  return children;
};

const PublicAuthRoute = ({ children }: any) => {
  const { isAuthenticated, authenticating } = useSession();
  const { getUserProfile, synchingUser } = useUser();

  useEffect(() => {
    if (isAuthenticated) {
      getUserProfile();
    }
  }, [isAuthenticated]);

  if (authenticating || synchingUser) {
    return <LoadingSpinner />;
  }

  return children;
};

export { PrivateRoute, AuthRoute, PublicAuthRoute };

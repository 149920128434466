import { Box, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../../styles/theme";

import { useAuth } from "../../auth/hooks/useAuth";
import { useBuilding } from "../../building/hooks/useBuilding";

import { MONTHS_IN_SPANISH } from "../../../constants/months-in-spanish";
import { Charge } from "../../../supabase/type/db-entities";
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import useMaintenanceFees from "../hooks/useMaintenanceFees";
import formatPaymentPeriodDate, {
  formatMaintenanceDate,
} from "../utils/formatPaymentPeriodDate";

export default function MaintenanceFeeSummary() {
  const { user } = useAuth();
  const { apartmentId } = useBuilding();
  const [buildingName, setBuildingName] = useState("");
  const [apartmentNumber, setApartmentNumber] = useState("");

  const { totalMaintenanceFees } = useMaintenanceFees();

  function getBuildingAndApartment(buildings: any, apartmentId: string | null) {
    for (const building of buildings) {
      const foundApartment = building.apartments.find(
        (ap: any) => ap.id === apartmentId
      );
      if (foundApartment) {
        setBuildingName(building.name);
        setApartmentNumber(foundApartment.apartment_number);
      }
    }
    return null;
  }

  useEffect(() => {
    if (user && isValidNonEmptyArray(user.buildings)) {
      getBuildingAndApartment(user?.buildings, apartmentId);
    }
  }, [user?.buildings, apartmentId]);

  return (
    <div>
      <Typography
        variant="h6"
        component="div"
        sx={{ fontSize: "1.2rem", mb: 2 }}
      >
        Resumen de pago
      </Typography>

      <Card sx={{ mb: theme.spacing(2), borderRadius: 4, p: 2 }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ fontSize: "1.1rem", mb: 1, fontWeight: "bold" }}
        >
          Cuota de mantenimiento
        </Typography>

        <Typography
          variant="caption"
          component="div"
          sx={{ fontSize: ".9rem" }}
        >
          {buildingName}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          sx={{ fontSize: ".9rem", mb: 1 }}
        >
          Departamento {apartmentNumber}
        </Typography>

        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              sx={{ fontSize: "1rem", mb: 1 }}
            >
              Periodo:{" "}
              {totalMaintenanceFees.data.dueDate
                ? formatPaymentPeriodDate(totalMaintenanceFees.data.dueDate)
                : null}{" "}
            </Typography>

            <Typography
              variant="caption"
              component="div"
              sx={{ fontSize: "1rem", mb: 1 }}
            >
              S/ {totalMaintenanceFees?.data.totalAmount}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: 2,
            borderTop: 1,
            borderColor: "#D9E2FF",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ fontSize: "1.4rem", mb: 1 }}
          >
            Total a pagar
          </Typography>

          <Typography
            variant="h6"
            component="div"
            sx={{ fontSize: "1.4rem", mb: 1 }}
          >
            S/ {totalMaintenanceFees.data.totalAmount}
          </Typography>
        </Box>
      </Card>
    </div>
  );
}

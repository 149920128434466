// src/features/auth/hooks/useAuth.ts

import { useCallback } from "react";
import { useAuthAnalytics } from "./useAuthAnalytics";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { invitationService } from "../../invitation/services/invitationService";
import { ROUTES } from "../../../config/routes";
import { enhancedAuthService } from "../services/enhancedAuthService";
import { authService } from "../services/authService";
import { supabase } from "../../../supabase/client"; // new import for session refresh

import useGlobalStore from "../../../state/store";
import useSession from "./useSession";
import useUser from "../../account/hooks/useUser";
import { useNavigate } from "react-router-dom";

// Tipos para el estado global y los parámetros de usuario
interface User {
  id: string;
  email: string;
  [key: string]: any;
}

// Definir los tipos para las funciones
interface UseAuth {
  user: User | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  signUpWithOtp: (email: string, password: string) => Promise<any>;
  verifyOtp: (email: string, token: string) => Promise<any>;
  updateUserPassword: (password: string) => Promise<any>;
  updateUserProfile: (userId: string, userData: any) => Promise<any>;
  completeRegistration: (email: string, userInfo: any) => Promise<User>;
  updateUserProfileField: (userId: string, field: string, value: any) => Promise<any>;
  setUser: (user: User | null) => void;
  isSynchUser: boolean;
}
export const useAuth = (): UseAuth => {
  const { user, setUser, purgeUser, getUserProfile } = useUser();
  const loading = useGlobalStore((state) => state.loading);
  const invitationId = useGlobalStore((state) => state.invitationId);

  const { session } = useSession();
  const { trackLogin, trackLogout, trackRegister } = useAuthAnalytics();
  const { navigateWithParams, clearParams, getParams } = useNavigation();
  const navigate = useNavigate();
  const login = useCallback(
    async (email: string, password: string): Promise<boolean> => {
      purgeUser();
      try {
        const { data, error } = await enhancedAuthService.signInWithPassword({
          email,
          password,
        });

        if (error) throw error;
        const baseUser: any = data!.user;

        trackLogin(baseUser.id, email);

        const { invitationToken, buildingId } = getParams();

        if (invitationToken) {
          try {
            await invitationService.createVisitFromInvitation({
              invitationId: invitationId ?? "",
              invitationToken,
              userId: baseUser.id
            });
          } catch (inviteError) {
            console.error("Error processing invitation:", inviteError);
          }
        }

        return true; // Indicate successful login
      } catch (error) {
        console.error("Login error:", error);
        return false; // Indicate failed login
      }
    },
    [setUser, trackLogin, navigateWithParams, getParams]
  );

  const logout = useCallback(async (): Promise<void> => {
    try {
      const { error } = await enhancedAuthService.signOut();
      if (error) throw error;

      trackLogout();
      clearParams();
      purgeUser();
      navigate(ROUTES.LOGIN);
    } catch (error) {
      console.error("Logout error:", error);
    }
  }, [setUser, trackLogout, clearParams, navigateWithParams]);

  const signUpWithOtp = useCallback(async (email: string, password: string) => {
    return authService.signUpWithOtp(email, password);
  }, []);

  const verifyOtp = useCallback(async (email: string, token: string) => {
    return authService.verifyOtp(email, token);
  }, []);

  const updateUserPassword = useCallback(async (password: string) => {
    return authService.updateUserPassword(password);
  }, []);

  const updateUserProfile = useCallback(
    async (userId: string, userData: any) => {
      return authService.updateUserProfile(userId, userData);
    },
    []
  );

  const updateUserProfileField = useCallback(
    async (userId: string, field: string, value: any) => {
      return authService.updateUserProfileField(userId, field, value);
    },
    []
  );

  const completeRegistration = useCallback(
    async (email: string, userInfo: any) => {
      // Attempt to refresh session if missing user information
      let currentSession = session;
      if (!currentSession?.user || !currentSession.user.id) {
        const { data: { session: freshSession } } = await supabase.auth.getSession();
        currentSession = freshSession;
      }
      if (!currentSession?.user || !currentSession.user.id) {
        throw new Error("Invalid session");
      }
      
      const updatedProfile = await authService.updateUserProfile(
        currentSession.user.id,
        { ...userInfo, email }
      );
      
      if (!updatedProfile) {
        throw new Error("Failed to update user profile");
      }
      
      // Construct full user
      const baseUser = {
        ...currentSession.user,
        ...updatedProfile,
      };
      
      // ...existing code...
      getUserProfile();
      trackRegister(baseUser.id, { email: baseUser.email });
      const { invitationToken, buildingId } = getParams();
      if (invitationToken) {
        await invitationService.createVisitFromInvitation({
          invitationId: invitationId ?? "",
          invitationToken,
          userId: baseUser.id
        });
      }
      
      navigateWithParams(
        buildingId ? ROUTES.APARTMENT_SELECTION : ROUTES.SUMMARY,
        buildingId ? { buildingId } : {}
      );
      
      return baseUser;
    },
    [getParams, navigateWithParams, trackRegister, setUser, session]
  );

  return {
    user,
    loading,
    login,
    logout,
    signUpWithOtp,
    verifyOtp,
    updateUserPassword,
    updateUserProfile,
    updateUserProfileField,
    completeRegistration,
    setUser,
    isSynchUser: loading && !user,
  };
};

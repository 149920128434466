import isTruthy from "../../../shared/utils/isTruthy";
import omit from "../../../shared/utils/omit";
import { PaymentReceipt, Voucher } from "../types";

export function isPaymentReceiptValid(receipt: PaymentReceipt) {
  const requiredFields = omit(receipt, []);

  for (const key in requiredFields) {
    const value = requiredFields[key];

    if (!isTruthy(value) || value == "N/A") {
      return false;
    }
  }
  return true;
}

export function getPaymentReceiptRequiredFieldError(
  field: keyof PaymentReceipt
) {
  switch (field) {
    case "amount":
      return "cantidad";

    case "payment_method":
      return "método de pago";

    case "bank_name":
      return "nombre del banco";

    case "payment_date":
      return "fecha de pago";

    case "reference_number":
      return "número de referencia";

    default:
      return null;
  }
}

export function getPaymentReceiptRequiredFieldErrors(receipt: PaymentReceipt) {
  let errors = [];
  for (let key in receipt) {
    if (!receipt[key] || receipt[key] == "N/A") {
      const error = getPaymentReceiptRequiredFieldError(key);

      errors.push(error);
    }
  }

  return errors;
}

export function generatePaymentReceiptMissingFieldError(
  receipt: PaymentReceipt
) {
  const missingFields = getPaymentReceiptRequiredFieldErrors(receipt);
  return `El voucher no contiene la siguiente información: ${missingFields.join(
    ","
  )}`;
}

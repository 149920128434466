// src/analytics/services/posthog.ts

import posthog from "posthog-js";
import { TEST_CONFIG, isTestEmail, getEnvironment } from "../config/testConfig";

type EventProperties = Record<string, any>;
type UserTraits = Record<string, any>;
type GroupProperties = Record<string, any>;
type TestEmailKeys = keyof typeof TEST_CONFIG.TEST_EMAILS;

export const init = (): void => {
  const environment = getEnvironment();

  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY || "", {
    api_host: import.meta.env.VITE_POSTHOG_HOST || "https://app.posthog.com",
    capture_pageview: true,
    autocapture: true,
    persistence_name: "posthog_session_recording_consent",
    disable_session_recording: environment === "production",
    loaded: (posthog) => {
      // Register environment properties
      posthog.register({
        environment,
        is_production: environment === "production",
        domain: window.location.hostname,
        client_version: "1.0.0",
      });

      // Log initialization in development
      if (environment !== "production") {
        console.log("PostHog initialized in", environment, "environment");
      }
    },
  });
};

export const trackEvent = (
  eventName: string,
  properties: EventProperties = {}
): void => {
  const environment = getEnvironment();
  const isTest = properties.email ? isTestEmail(properties.email) : false;

  const eventProperties = {
    ...properties,
    environment,
    is_production: environment === "production",
    is_test_event: isTest,
    client_timestamp: new Date().toISOString(),
  };

  if (environment !== "production") {
    console.log("Tracking event:", eventName, eventProperties);
  }

  posthog.capture(eventName, eventProperties);
};

export const identifyUser = (userId: string, traits: UserTraits = {}): void => {
  const environment = getEnvironment();
  const isTest = traits.email ? isTestEmail(traits.email) : false;

  const userProperties = {
    ...traits,
    environment,
    is_production: environment === "production",
    is_test_user: isTest,
    identified_at: new Date().toISOString(),
  };

  posthog.identify(userId, userProperties);

  posthog.register({
    environment,
    is_production: environment === "production",
    is_test_user: isTest,
  });
};

export const resetUser = (): void => {
  posthog.reset();

  if (getEnvironment() !== "production") {
    console.log("User reset");
  }
};

export const isFeatureEnabled = (
  flagKey: string,
  defaultValue = false
): boolean | undefined => {
  const environment = getEnvironment();

  if (environment !== "production") {
    console.log(
      `Feature flag ${flagKey} would be: ${defaultValue} (${environment})`
    );
    return defaultValue;
  }

  return posthog.isFeatureEnabled(flagKey, { send_event: defaultValue });
};

export const getFeatureFlagValue = (flagKey: string): any => {
  const environment = getEnvironment();

  if (environment !== "production") {
    console.log(`Feature flag ${flagKey} value requested (${environment})`);
    return null;
  }

  return posthog.getFeatureFlagPayload(flagKey);
};

export const setAnalyticsGroup = (
  groupType: string,
  groupKey: string,
  groupProperties: GroupProperties = {}
): void => {
  const environment = getEnvironment();

  const properties = {
    ...groupProperties,
    environment,
    is_production: environment === "production",
    updated_at: new Date().toISOString(),
  };

  if (environment !== "production") {
    console.log("Setting group:", groupType, groupKey, properties);
  }

  posthog.group(groupType, groupKey, properties);
};

export const getTestEmail = (feature: string): string => {
  const upperFeature = feature.toUpperCase() as TestEmailKeys;

  if (upperFeature in TEST_CONFIG.TEST_EMAILS) {
    return TEST_CONFIG.TEST_EMAILS[upperFeature];
  }

  return TEST_CONFIG.TEST_EMAILS.DEV;
};

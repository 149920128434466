import { Fragment } from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Skeleton,
  IconButton,
  Alert,
  Snackbar
} from "@mui/material";
import theme from "../../../styles/theme";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useBankAccounts from "../hooks/useBankAccounts";
import useClipboard from "../../../shared/hooks/useClipboard";
import useSnackbar from "../../../shared/hooks/useSnackbar";
import createNumberArray from "../../../shared/utils/createNumberArray";
import { BankAccount } from "../../../supabase/type/db-entities";

const BankInformationSkeleton = () => (
  <Card
    sx={{
      mb: theme.spacing(2),
      borderRadius: 4,
      borderColor: "red",
      borderWidth: 1,
    }}
  >
    <CardContent
      sx={{
        py: theme.spacing(1.5),
        px: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
      }}
    >
      {createNumberArray(6).map((num, index) => (
        <Box
          key={num + index}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <Box>
            <Skeleton variant="text" width={160} height={24} />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Skeleton variant="circular" width={24} height={24} />
          </Box>
        </Box>
      ))}
    </CardContent>
  </Card>
);

export default function BankingInformation() {
  const { openSnackbar, handleCloseSnackbar, handleOpenSnackbar } = useSnackbar();
  const { hasCopied, copyToClipboard, copiedText } = useClipboard(800);
  const { bankAccounts, bankAccountsNotFound } = useBankAccounts();

  const getAccountDetails = (account: BankAccount) => {
    const details = [];

    

    if (account.payment_method === 'bank_transfer') {
      

      if (account.account_owner) {
        details.push({
          label: "Titular",
          value: account.account_owner,
          copyable: false
        });
      }

      if (account.account_number) {
        details.push({
          label: "Nro de Cuenta",
          value: account.account_number,
          copyable: true
        });
      }

      if (account.cci_code) {
        details.push({
          label: "Código Interbancario",
          value: account.cci_code,
          copyable: true
        });
      }
    }
    else if (account.payment_method === 'digital_wallet' && account.phone_number) {
      details.push({
        label: "Número",
        value: account.phone_number,
        copyable: true
      });
    }

    if (account.currency) {
      details.push({
        label: "Moneda",
        value: account.currency || "PEN",
        copyable: false
      });
    }
    return details;
  };

  if (bankAccounts.loading) {
    return <BankInformationSkeleton />;
  }

  return (
    <Fragment>
      <Card
        sx={{
          borderRadius: 4,
          borderColor: "red",
          borderWidth: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <CardContent
          sx={{
            py: theme.spacing(1.5),
            px: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
          >
            Cuenta a depositar
          </Typography>

          {bankAccounts.data.map((account, index) => {
            const accountTitle = account.alias || (
              account.payment_method === 'bank_transfer'
                ? `${account.provider_name} - ${account.account_type === 'savings' ? 'Ahorros' : 'Corriente'}`
                : account.provider_name
            );

            const details = getAccountDetails(account);

            return (
              <Box
                key={index}
                sx={{
                  mt: 2,
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    mb: 1
                  }}
                >
                  {accountTitle}
                </Typography>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {details.map((detail, detailIndex) => (
                    <Box key={detailIndex} sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          width: "30%",
                          fontWeight: 400,
                        }}
                      >
                        {detail.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.9rem",
                          color: "#212121",
                          fontWeight: 500,
                          flex: 1,
                        }}
                      >
                        {detail.value}
                      </Typography>
                      {detail.copyable && (
                        <IconButton
                          size="small"
                          sx={{
                            backgroundColor: hasCopied && copiedText === detail.value ? "#e6eeff" : "transparent",
                          }}
                          onClick={() => {
                            copyToClipboard(detail.value);
                            handleOpenSnackbar();
                          }}
                        >
                          <ContentCopyIcon
                            sx={{
                              fontSize: 18,
                              color: hasCopied && copiedText === detail.value ? "#1976d2" : "#9e9e9e",
                            }}
                          />
                        </IconButton>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            );
          })}

          {bankAccountsNotFound && (
            <Typography
              variant="caption"
              component="div"
              sx={{ fontSize: "1rem", color: "#5D5E61" }}
            >
              Sin Información bancaria disponible
            </Typography>
          )}
        </CardContent>
      </Card>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontWeight: "400" }}
        >
          <Typography variant="button"> {copiedText}</Typography> copiado al
          portapapeles.
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
// src/features/incident/components/list/IncidentCard.js
import React, { useState, useCallback } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { MoreVertical, MessageSquare, Image } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import { StatusBadge, PriorityBadge } from "../shared";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../config/routes";

const IncidentCard = React.memo(
  ({ incident, onStatusChange, onClick, showActions = true }: any) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = useCallback((event: any) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const handleStatusChange = useCallback(
      (newStatus: any) => {
        handleClose();
        onStatusChange?.(incident.id, newStatus);
      },
      [handleClose, onStatusChange, incident.id]
    );

    const mediaCount = incident.media_urls?.length || 0;
    const commentCount = incident.comments?.length || 0;

    const incidentDetailPath = `${ROUTES.INCIDENTS}/${incident.id}`;

    return (
      <Link style={{ textDecoration: "none" }} to={incidentDetailPath}>
        <Card
          sx={{ cursor: "pointer" }}
          onClick={(event) => {
            onClick(incident.id);
          }}
        >
          <CardContent>
            <div>
              <Stack spacing={2}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Stack spacing={1}>
                    <Typography variant="h6">{incident.title}</Typography>
                    <Stack direction="row" spacing={1}>
                      <StatusBadge status={incident.status} />
                      <PriorityBadge priority={incident.priority} />
                    </Stack>
                  </Stack>

                  {showActions && (
                    <IconButton onClick={handleMenuClick}>
                      <MoreVertical />
                    </IconButton>
                  )}
                </Box>

                <Typography variant="body2" color="text.secondary">
                  {incident.description}
                </Typography>

                {incident.location_details && (
                  <Typography variant="body2" color="text.secondary">
                    📍 {incident.location_details}
                  </Typography>
                )}

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    {mediaCount > 0 && (
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Image size={16} />
                        <Typography variant="caption">{mediaCount}</Typography>
                      </Stack>
                    )}
                    {commentCount > 0 && (
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <MessageSquare size={16} />
                        <Typography variant="caption">
                          {commentCount}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>

                  <Typography variant="caption" color="text.secondary">
                    {formatDistanceToNow(new Date(incident.created_at), {
                      addSuffix: true,
                      locale: es,
                    })}
                  </Typography>
                </Box>
              </Stack>
            </div>
          </CardContent>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              onClick={(event) => {
                event.preventDefault();
                handleStatusChange("resolved");
              }}
            >
              Marcar como resuelto
            </MenuItem>
          </Menu>
        </Card>
      </Link>
    );
  }
);

export const IncidentCardSkeleton = () => (
  <Card>
    <CardContent>
      <Stack spacing={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          width={"100%"}
        >
          <Stack width={"100%"}>
            <Skeleton variant="text" width={"50%"} height={24} />
            <Stack direction="row" spacing={1}>
              <Skeleton variant="text" width={56} height={24} />
              <Skeleton variant="text" width={56} height={24} />
            </Stack>
          </Stack>

          <Box>
            <Skeleton variant="circular" width={24} height={24} />
          </Box>
        </Box>

        <Stack>
          <Skeleton variant="text" width={"80%"} height={12} />
          <Skeleton variant="text" width={"85%"} height={12} />
          <Skeleton variant="text" width={"70%"} height={12} />
        </Stack>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Skeleton variant="text" width={"30%"} height={12} />{" "}
          <Skeleton variant="text" width={"20%"} height={12} />
        </Box>
      </Stack>
    </CardContent>
  </Card>
);

export default IncidentCard;

// src/styles/theme.ts

import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    status?: {
      pending?: {
        main: string;
        light: string;
      };
      resolved?: {
        main: string;
        light: string;
      };
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#315CAC",
    },
    background: {
      default: "#f5f5f5",
    },
    status: {
      pending: {
        main: "#B98900",
        light: "#FFF8E6",
      },
      resolved: {
        main: "#2E7D32",
        light: "#E8F5E9",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "28px",
          padding: "12px 24px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "16px",
            backgroundColor: "white",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 500,
          fontSize: "1rem",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "3px",
        },
      },
    },
  },
});

export default theme;

import { useEffect, useState } from "react";
import { secureApi } from "../../../supabase/secureApi";
import { Charge, PaymentAllocation } from "../../../supabase/type/db-entities";
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import useGlobalStore from "../../../state/store";
import findOldestInvoice from "../utils/findOldestInvoice";
import { useBuilding } from "../../building/hooks/useBuilding";

type ChargeUnPaidAmount = Charge & {
  total_amount: number;
};

function useMaintenanceFees() {
  const maintenanceFees = useGlobalStore((state) => state.maintenanceFees);
  const setMaintenanceFees = useGlobalStore(
    (state) => state.setMaintenanceFees
  );
  const totalMaintenanceFees = useGlobalStore(
    (state) => state.totalMaintenanceFees
  );
  const setTotalMaintenanceFees = useGlobalStore(
    (state) => state.setTotalMaintenanceFees
  );

  /** Discard refetch when we already have total maintenance fees in global store */
  const hasCachedTotalMaintainFees = Object.values(
    totalMaintenanceFees.data
  ).every((value) => value !== null);

  const getMaintenanceFees = async ({
    apartmentId,
  }: {
    apartmentId: string;
  }) => {
    if (!apartmentId) return;
    try {
      setTotalMaintenanceFees({
        data: {
          totalAmount: null,
          issueDate: null,
          dueDate: null,
        },
        loading: true,
        error: null,
      });

      setMaintenanceFees({
        data: [],
        loading: true,
        error: null,
      });
      const charges = await secureApi.get("charges", {
        select: "*, transactions(*)",
        filter: { apartment_id: apartmentId },
      });

      const chargesWithPaidAmount: ChargeUnPaidAmount[] = await Promise.all(
        charges.map(async (charge: Charge) => {
          const payments = await secureApi.get("payment_allocations", {
            select: "*",
            filter: { charge_id: charge.id },
          });
          console.log(`Pagos para el cargo ${charge.id}:`, payments);

          const amountAllocatedToCharge =
            payments && payments.length > 0
              ? payments.reduce(
                  (acc: number, pago: PaymentAllocation) =>
                    acc + pago.allocated_amount,
                  0
                )
              : 0;
              console.log(`Monto asignado al cargo ${charge.id}:`, amountAllocatedToCharge);
          return {
            ...charge,
            total_amount: charge.total_amount - amountAllocatedToCharge,
          };
        })
      );

      const totalAmount = chargesWithPaidAmount.reduce(
        (accumulator, charge) => accumulator + charge.total_amount,
        0
      );

      const oldestCharge = findOldestInvoice(chargesWithPaidAmount);

      const issueDate = oldestCharge?.issue_date || null;
      const dueDate = oldestCharge?.due_date || null;

      setTotalMaintenanceFees({
        data: {
          // totalAmount: totalAmount > 0 ? totalAmount : 0,
          totalAmount,
          issueDate,
          dueDate,
        },
        loading: false,
        error: null,
      });

      setMaintenanceFees({
        data: chargesWithPaidAmount,
        loading: false,
        error: null,
      });
    } catch (error) {
      setTotalMaintenanceFees({
        data: {
          totalAmount: null,
          issueDate: null,
          dueDate: null,
        },
        loading: false,
        error: "Fallo al obtener detalles del edificio",
      });

      setMaintenanceFees({
        data: [],
        loading: false,
        error: "Fallo al obtener detalles del edificio:",
      });
      console.error("Fallo al obtener detalles del edificio:", error);
    }
  };

  const maintenanceFeesNotFound =
    !totalMaintenanceFees.loading && !hasCachedTotalMaintainFees;

  return {
    totalMaintenanceFees,

    getMaintenanceFees,
    maintenanceFeesNotFound,
    maintenanceFees,
    hasCachedTotalMaintainFees,
  };
}

export default useMaintenanceFees;

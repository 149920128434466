// src/features/navigation/hooks/useNavigation.ts

import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../config/routes';
import useGlobalStore from '../../state/store';

// Definir los tipos de los parámetros de navegación
interface NavigationParams {
  invitationToken: string | null;
  buildingId: string | null;
}

// Definir los tipos de las funciones del hook
interface UseNavigationReturn {
  getParams: () => NavigationParams;
  navigateWithParams: (route: string, additionalParams?: Record<string, any>) => void;
  clearParams: () => void;
  clearUrlParams: () => void;
  getRedirectPath: () => string;
}

export const useNavigation = (): UseNavigationReturn => {
  const location = useLocation();
  const invitationToken = useGlobalStore((state) => state.invitationToken);
  const setInvitationToken = useGlobalStore((state) => state.setInvitationToken);
  const setInvitationId = useGlobalStore((state) => state.setInvitationId);
  const buildingId = useGlobalStore((state) => state.buildingId);
  const setBuildingId = useGlobalStore((state) => state.setBuildingId);

  const getParams = useCallback((): NavigationParams => {
    const searchParams = new URLSearchParams(location.search);
    return {
      invitationToken: searchParams.get('token') || invitationToken,
      buildingId: searchParams.get('buildingId') || buildingId,
    };
  }, [location.search, invitationToken, buildingId]);

  const navigateWithParams = useCallback((route: string, additionalParams: Record<string, any> = {}) => {
    const params = getParams();
    const searchParams = new URLSearchParams();

    if (params.invitationToken) searchParams.set('token', params.invitationToken);
    if (params.buildingId) searchParams.set('buildingId', params.buildingId);

    Object.entries(additionalParams).forEach(([key, value]) => {
      if (value !== null && value !== undefined) searchParams.set(key, value);
    });

    const queryString = searchParams.toString();
    const fullPath = queryString ? `${route}?${queryString}` : route;

    console.log('Navigating to:', fullPath);

    // Force a page reload
    window.location.href = fullPath;
  }, [getParams]);

  const clearParams = useCallback(() => {
    setInvitationToken(null);
    setInvitationId(null); 
    setBuildingId(null);
  }, [setInvitationToken, setInvitationId, setBuildingId]);

  const clearUrlParams = useCallback(() => {
    window.history.replaceState({}, '', window.location.pathname);
  }, []);

  const getRedirectPath = useCallback((): string => {
    const { invitationToken, buildingId } = getParams();
    if (invitationToken || buildingId)
    {
      return ROUTES.SUMMARY;
    }
    return ROUTES.SUMMARY;
  }, [getParams]);

  return {
    getParams,
    navigateWithParams,
    clearParams,
    clearUrlParams,
    getRedirectPath,
  };
};

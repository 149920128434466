import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import theme from "../../../styles/theme";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Typography,
  Box,
  TextField,
  Button,
  LinearProgress,
  CircularProgress,
  Skeleton,
  Modal,
} from "@mui/material";
import { useAuth } from "../../auth/hooks/useAuth";
import { supabaseStorage } from "../../../storage";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ImageViewerVaucher from "./ImageViewerVaucher";
import isValidNonEmptyObject from "../../../shared/utils/isValidNonEmptyObject";
import { useMaintenanceFeesAnalytics } from "../hooks/useMaintenanceFeesAnalytics";
interface UploadVoucherProps {
  buildingId: string;
  onImageProcessingStart: () => void;
  onImageProcessingComplete: (imageResult: any) => void;
  onImageProcessingFail: (errorMessage: string) => void;
  onDeleteImageUpload: () => void;
}

export default function UploadVoucher({
  buildingId,
  onImageProcessingComplete,
  onImageProcessingFail,
  onImageProcessingStart,
  onDeleteImageUpload,
}: UploadVoucherProps) {
  const { user } = useAuth();

  const [loadingImageUpload, setLoadingImageUpload] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [urlImage, setUrlImage] = useState("");
  const [nameImage, setNameImage] = useState("");
  const [startLoading, setStartLoading] = useState(false);
  const [evidence_url, setEvidenceUrl] = useState("");
  const [evidence_path, setEvidencePath] = useState("");
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const { trackVoucherUploaded } = useMaintenanceFeesAnalytics();

  const [amount, setAmount] = useState(0);

  const handleCardClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: any) => {
    onImageProcessingStart();
    setLoadingImageUpload(true);
    setStartLoading(true);
    const file = event.target.files[0];
    if (Boolean(!file)) {
      setLoadingImageUpload(false);
      return;
    }
    const upload = await supabaseStorage.uploadFile(file, {
      path: `vocuchers/edificio:${buildingId}/${user?.first_name}`,
    });

    const stringParts: string[] = upload?.path?.split("/");
    const name: string = stringParts.slice(3).join("/");
    setUrlImage(upload?.url);
    setNameImage(name);
    const base64 = await convertToBase64(file);
    setImageBase64(base64 as string);
    setEvidenceUrl(upload?.url);
    setEvidencePath(upload?.path);
  };

  useEffect(() => {
    if (imageBase64) {
      sendImageToServer(imageBase64);
    }
  }, [imageBase64]);

  const sendImageToServer = async (base64: any) => {
    try {
      const url = `${
        import.meta.env.VITE_URL_BASE_WEBHOOKS_DOMUS
      }/dommus/process-image-ia`;

      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ image: base64 }),
      });
      const { result } = await response.json();

      trackVoucherUploaded({
        userId: user?.id!,
        buildingId,
        amount: result?.amount || 0, 
      });

      
      setImageBase64(null);
      setLoadingImageUpload(false);

      if (isValidNonEmptyObject(result) && evidence_url) {
        setAmount(result?.amount ?? 0);
        onImageProcessingComplete({ ...result, evidence_url, evidence_path });
      }
    } catch (error) {
      console.error(error);
      setLoadingImageUpload(false);
      setImageBase64(null);
      onImageProcessingFail(
        "Algo salió mal al procesar el comprobante de pago"
      );
    }
  };

  const convertToBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDeleteUpload = () => {
    setAmount(0);
    setImageBase64(null);
    setEvidenceUrl("");
    setEvidencePath("");
    setUrlImage("");
    setNameImage("");
    setStartLoading(false);
    onDeleteImageUpload();
  };

  return (
    <Box>
      <Typography
        variant="h6"
        component="div"
        sx={{ fontSize: "1.1rem", mb: 1, fontWeight: "bold" }}
      >
        Cargar Voucher
      </Typography>

      <Typography
        variant="body2"
        sx={{ fontSize: "0.8rem", mt: 1, mb: 3, color: "#5D5E61" }}
      >
        Asegúrate de que la imagen del voucher sea clara y legible al cargarla.
        Esto nos ayudará a procesar tu pago de manera efectiva.
      </Typography>

      <Box
        sx={{
          border: "2px dashed #CCE1FF",
          borderRadius: 6,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 4,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <UploadFileIcon sx={{ color: "#5D5E61" }} />
          <Typography
            variant="body1"
            sx={{ color: "#5D5E61", fontSize: "0.9rem" }}
          >
            Arrastra tu archivo aquí o
          </Typography>
        </Box>

        <Button
          variant="text"
          onClick={handleCardClick}
          sx={{
            backgroundColor: "#E1E8FF",
            borderRadius: "20px",
            textTransform: "none",
            fontSize: "0.9rem",
            mx: 4,
            color: "#3B82F6",
            "&:hover": {
              backgroundColor: "#D2DAF9",
            },
          }}
        >
          Seleccionar archivo
        </Button>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleFileChange}
        />
      </Box>

      {startLoading && (
        <Box
          sx={{
            border: "1px solid #CCE1FF",
            borderRadius: 6,
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 4,
            backgroundColor: "#F4F8FA",
          }}
        >
          <Box sx={{ ml: 2 }}>
            {loadingImageUpload ? (
              <CircularProgress size={15} />
            ) : (
              <CheckCircleIcon sx={{ color: "#16A34A" }} />
            )}
          </Box>
          <ImageViewerVaucher
            pathImage={evidence_path}
            imageUrl={urlImage}
            nameImage={nameImage}
            onDelete={handleDeleteUpload}
          />
        </Box>
      )}

      <TextField
        id="outlined-basic"
        label="Monto Pagado"
        variant="outlined"
        value={amount}
        disabled={true}
        sx={{ my: 4 }}
      />
    </Box>
  );
}

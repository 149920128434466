// src/config/sidebarConfig.tsx
import React from "react";
import {
  Home,
  CalendarToday,
  Chat,
  LocalLaundryService,
  LocalShipping,
  MonetizationOn,
  OpacityOutlined,
  People,
  Room,
  Warning,
  Article
} from "@mui/icons-material";
import { ROUTES } from "./routes"; // Ensure the routes file has the needed keys

export type SidebarItem = {
  text: string;
  icon: React.ReactElement;
  route: string;
  premium: boolean;
};

export const sidebarItems: SidebarItem[] = [
  { text: "Resumen", icon: <Home />, route: ROUTES.SUMMARY, premium: false },

  {
    text: "Mantenimiento",
    icon: <Article />,
    route: ROUTES.MAINTENANCE,
    premium: false,
  },
  {
    text: "Visitas",
    icon: <People />,
    route: ROUTES.VISITS,
    premium: false,
  },
  {
    text: "Incidencias",
    icon: <Warning />,
    route: ROUTES.INCIDENTS,
    premium: false,
  },
];
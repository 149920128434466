// src/features/invitation/services/invitationService.js

import { secureApi } from "../../../supabase/secureApi";
import { supabase } from "../../../supabase/client";

import { publicApi } from "../../../supabase/publicApi";

import { getSession } from "../../auth/hooks/useSession";
import isTruthy from "../../../shared/utils/isTruthy";

export const invitationService = {
  async fetchInvitationByToken(token: any) {
    try {
      const isAuthenticated = Boolean(getSession()?.access_token);

      const api = isAuthenticated ? secureApi : publicApi;

      // Use a single query with full joins for both auth and non-auth
      const invitation = await api.get("invitations", {
        select: `
          id,
          date,
          invitation_token,
          is_active,
          resident:users (
            first_name,
            last_name
          ),
          apartment:apartments (
            apartment_number,
            building:buildings (
              name,
              address
            )
          )
        `,
        filter: {
          invitation_token: token,
          is_active: true,
        },
        single: true,
      });

      if (!invitation) {
        return null;
      }

      // Transform response to match expected format
      return {
        ...invitation,
        apartment: {
          apartment_number: invitation.apartment?.apartment_number || "N/A",
          building: {
            name: invitation.apartment?.building?.name || "N/A",
            address: invitation.apartment?.building?.address || "N/A",
          },
        },
      };
    } catch (error) {
      console.error("Error fetching invitation:", error);
      throw error;
    }
  },

  async checkExistingVisit(invitationId: any, userId: any) {
    try {
      const result = await secureApi.get("visits", {
        select: `
          id,
          invitation:invitations (
            invitation_token
          )
        `,
        filter: {
          invitation_id: invitationId,
          visitor_id: userId,
        },
        single: true,
      });
      return result;
    } catch (error: any) {
      if (error.code === "PGRST116" || error.status === 406) {
        // This error code means no rows were returned, or the request was not acceptable
        // In both cases, we assume no existing visit
        return null;
      }
      console.error("Error checking existing visit:", error);
      throw error;
    }
  },

  async createVisitFromInvitation({
    invitationId,
    invitationToken,
    userId,
  }: {
    invitationId: string;
    invitationToken: string;
    userId: string;
  }) {
    try {
      // First, check if the visit already exists
      const existingVisit = await this.checkExistingVisit(invitationId, userId);

      if (existingVisit) {
        return {
          visitId: existingVisit.id,
          isExisting: isTruthy(existingVisit?.id),
        };
      }

      // If no existing visit, create a new one
      const result = await secureApi.rpc("create_visit_from_invitation", {
        p_invitation_token: invitationToken,
        p_visitor_id: userId,
      });

      if (!result) {
        throw new Error("No visit created");
      }

      return { visitId: result, isExisting: false };
    } catch (error: any) {
      if (error.code === "23505") {
        // This is the error code for duplicate key violation
        return { visitId: null, isExisting: true };
      }
      console.error("Error creating visit from invitation:", error);
      throw error;
    }
  },

  async fetchUserVisits() {
    try {
      const user = (await supabase.auth.getSession()).data.session?.user;

      if (!user) throw new Error("No authenticated user");

      // Get user's public ID

      const userData = await secureApi.get("users", {
        select: "id",
        filter: { auth_user_id: user.id },
        single: true,
      });

      // Simplified select query without deep nesting
      return secureApi.get("visits", {
        select: `
          id,
          status,
          invitation:invitations!inner (
            date,
            resident:users!inner (
              first_name,
              last_name,
              profile_pic
            ),
            apartment:apartments!inner (
              apartment_number,
              building:buildings!inner (
                name,
                address
              )
            )
          )
        `,
        filter: { visitor_id: userData.id },
        order: { created_at: "desc" },
      });
    } catch (error) {
      console.error("Error fetching visits:", error);
      throw error;
    }
  },

  async fetchVisitDetails(visitId: any) {
    try {
      // Get all visit data in a single query with proper nesting
      const visit = await secureApi.get("visits", {
        select: `
          id,
          status,
          invitation:invitations!inner (
            date,
            resident:users!inner (
              first_name,
              last_name,
              profile_pic
            ),
            apartment:apartments!inner (
              apartment_number,
              building:buildings!inner (
                name,
                address
              )
            )
          )
        `,
        filter: { id: visitId },
        single: true,
      });

      if (!visit) {
        throw new Error("Visit not found");
      }

      // Return with proper structure and fallbacks
      return {
        ...visit,
        invitation: {
          ...visit.invitation,
          apartment: {
            apartment_number:
              visit.invitation?.apartment?.apartment_number || "N/A",
            building: {
              name: visit.invitation?.apartment?.building?.name || "N/A",
              address: visit.invitation?.apartment?.building?.address || "N/A",
            },
          },
        },
      };
    } catch (error) {
      console.error("Error fetching visit details:", error);
      throw error;
    }
  },
};

// src/features/incident/hooks/useIncident.ts

import { useCallback, useState, useEffect } from "react";
import { incidentService } from "../services/incidentService";
import { useAnalytics } from "../../../analytics/hooks/useAnalytics";
import useGlobalStore from "../../../state/store";
import { useIncidentAnalytics } from "./useIncidentAnalytics";

export const useIncident = (incidentId: string | null = null) => {
  const user = useGlobalStore((state) => state.user);
  const userLoading = useGlobalStore((state) => state.loading);
 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [incident, setIncident] = useState<any>(null);
  const [incidents, setIncidents] = useState([]); // Add this line
  const { trackIncidentCreated, trackCommentAdded , trackStatusChanged } = useIncidentAnalytics();

  const fetchIncident = useCallback(async () => {
    if (!incidentId || !user?.id) {
      setError(
        !incidentId ? "No incident ID provided" : "User not authenticated"
      );
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const data = await incidentService.fetchIncidentById(incidentId, user.id);

      const transformedIncident = {
        ...data,
        created_at: new Date(data.created_at),
        updated_at: new Date(data.updated_at),
        due_date: data.due_date ? new Date(data.due_date) : null,
        resolution_date: data.resolution_date
          ? new Date(data.resolution_date)
          : null,
        media_urls: Array.isArray(data.media_urls) ? data.media_urls : [],
        metadata: typeof data.metadata === "object" ? data.metadata : {},
        comments: Array.isArray(data.comments) ? data.comments : [],
        status_history: Array.isArray(data.status_history)
          ? data.status_history
          : [],
      };

      console.log(
        "Setting incident state with comments:",
        transformedIncident.comments.length
      );

      setIncident(transformedIncident);
      return transformedIncident;
    } catch (err: any) {
      const errorMessage = err.message || "Error al cargar la incidencia";
      setError(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [incidentId, user?.id]);

  const fetchUserIncidents = useCallback(async () => {
    if (!user?.id) {
      setError("User not authenticated");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await incidentService.fetchUserIncidents(user.id);
      console.log("Fetched incidents:", response); // Add this debug log
      setIncidents(response || []); // Ensure we always set an array
      return response;
    } catch (err: any) {
      console.error("Error fetching incidents:", err); // Add error logging
      const errorMessage = err.message || "Error al cargar las incidencias";
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [user?.id]);

  const createIncident = useCallback(
    async (incidentData: any) => {
      try {
        setLoading(true);
        setError(null);
        const result = await incidentService.createIncident(
          incidentData,
          user.id
        );

        setLoading(false);

        // Track the event if successful
        if (result?.data?.incident_id) {
          trackIncidentCreated(result.data.incident_id, { 
            category: incidentData.category,
            priority:  incidentData.priority,
          });
        }

        return result;
      } catch (err: any) {
        const errorMessage = err.message || "Error al crear la incidencia";
        setError(errorMessage);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [user, trackIncidentCreated]
  );

  const updateIncidentStatus = useCallback(
    async (incidentId: any, newStatus: any, notes = null) => {
      setLoading(true);
      setError(null);
      try {
        const result = await incidentService.updateIncidentStatus(
          incidentId,
          newStatus,
          user.id,
          notes
        );

        if (result?.data?.incident_id) { 
          trackStatusChanged(result.data.incident_id, newStatus);
        }

        return result;
      } catch (err: any) {
        const errorMessage = err.message || "Error al actualizar el estado";
        setError(errorMessage);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [user, trackStatusChanged]
  );

  const addComment = useCallback(
    async (comment: string, mediaFiles = [], isInternal = false) => {
      if (!incidentId || !user?.id) {
        setError("Missing incident ID or user not authenticated");
        return;
      }

      const buildingId: any = incident?.building_id;
      if (!buildingId) {
        setError("Missing building ID from incident data");
        console.error("Incident data:", incident);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const cleanComment = String(comment).trim();
        const cleanMediaFiles = Array.isArray(mediaFiles) ? mediaFiles : [];

        console.log("Adding comment:", {
          incidentId,
          userId: user.id,
          buildingId,
          comment: cleanComment,
          mediaFiles: cleanMediaFiles,
        });

        const result = await incidentService.addComment(
          incidentId,
          user.id,
          cleanComment,
          cleanMediaFiles,
          isInternal,
          buildingId
        );

        if (result.success) {
          await fetchIncident();
          trackCommentAdded(incidentId, cleanMediaFiles.length > 0);
        }

        return result;
      } catch (err: any) {
        const errorMessage = err.message || "Error al agregar el comentario";
        setError(errorMessage);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [incidentId, user?.id, incident, fetchIncident, trackCommentAdded]
  );

  return {
    incident,
    incidents,
    createIncident,
    updateIncidentStatus,
    addComment, // Add this to the return object
    fetchIncident,
    fetchUserIncidents,
    loading: loading || userLoading,
    error,
  };
};

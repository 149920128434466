import { MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useUser from "../../account/hooks/useUser";
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import { useBuilding } from "../hooks/useBuilding";
import isValidNonEmptyObject from "../../../shared/utils/isValidNonEmptyObject";
import useGetBuildingData from "../hooks/useGetBuidingData";
import { useEffect } from "react";

export function SelectBuildingApartment() {
  const { user } = useUser();
  useGetBuildingData();
  const { setApartmentId, setBuildingId, apartmentId, buildingId } =
    useBuilding();

  const hasBuildings = isValidNonEmptyArray(user?.buildings);

  const { getBuildingAssociatedData } = useGetBuildingData();

  const buildings = user.buildings ?? [];

  const formatApartmentForSelect = () => {
    if (!hasBuildings) return [];

    const apartments: {
      buildingId: string;
      apartmentId: string;
      name: string;
    }[] = [];

    buildings.forEach((building) => {
      if (isValidNonEmptyArray(building.apartments)) {
        building.apartments.forEach((apartment) => {
          const newApartment = {
            name: `${apartment?.apartment_number}-${building?.name}`,
            apartmentId: apartment?.id,
            buildingId: building?.id,
          };

          apartments.push(newApartment);
        });
      }
    });

    return apartments;
  };

  const handleSelectBuildingApartment = ({
    newApartmentId,
    newBuildingId,
  }: {
    newApartmentId: string;
    newBuildingId: string;
  }) => {
    if (newApartmentId !== buildingId) {
      setApartmentId(newApartmentId);
    }

    if (newBuildingId !== buildingId) {
      setBuildingId(newBuildingId);
    }
  };

  const apartmentsSelect = formatApartmentForSelect();
  const handleApartmentChange = (event: SelectChangeEvent) => {
    const apartmentId = event.target.value as string;

    const apartment = apartmentsSelect.find(
      (apartment) => apartment.apartmentId === apartmentId
    );

    if (apartment && isValidNonEmptyObject(apartment)) {
      handleSelectBuildingApartment({
        newApartmentId: apartment.apartmentId,
        newBuildingId: apartment.buildingId,
      });
    }
  };

  if (!hasBuildings) return null;

  useEffect(() => {
    if (buildingId && apartmentId) {
      getBuildingAssociatedData({ buildingId, apartmentId });
    }
  }, [buildingId, apartmentId]);

  return (
    <StyledSelect
      variant="outlined"
      IconComponent={ArrowDropDownIcon}
      displayEmpty
      defaultValue={apartmentId}
      value={apartmentId}
      onChange={handleApartmentChange}
    >
      {apartmentsSelect.map((apartment) => (
        <MenuItem key={apartment.apartmentId} value={apartment.apartmentId}>
          {apartment.name}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: 100,
  color: theme.palette.primary.main,
  minWidth: 250,
  paddingLeft: 20,
  fontSize: 15,
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
  },
  "& .MuiSvgIcon-root": {
    right: "auto", // quita el posicionamiento por defecto a la derecha
    left: "0", // coloca el ícono a la izquierda
    position: "absolute",
    pointerEvents: "none",
    color: theme.palette.primary.main,
    transform: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Quita el borde, si así lo deseas
  },
}));

export default SelectBuildingApartment;

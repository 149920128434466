import { useState, useRef } from "react";
import {
    Box,
    Typography,
    Button,
    TextField,
    Alert,
    CircularProgress
} from "@mui/material";
import { authService } from "../../auth/services/authService";

interface InfoFieldProps {
    label: string;
    value: string;
    editable?: boolean;
    onEdit?: () => void;
}

export default function General({ user, setUser }: {user: any; setUser: (user: any) => void;
}
)  {
    const [editingField, setEditingField] = useState<string | null>(null);
    const [fieldValue, setFieldValue] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [submitting, setSubmitting] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const fieldMapping = {
        "Nombre": "first_name",
        "Apellido": "last_name",
        "DNI": "document_number",
        "Telefono": "phone",
    };

    const handleEditField = (title: string, value: string) => {
        setEditingField(title);
        setFieldValue(value === "No Añadido" ? "" : value);
        setError(null);
    };

    const handleSaveField = async () => {
        if (!user?.id || !editingField) {
            setError("No se pudo identificar el usuario o el campo");
            return;
        }

        setSubmitting(true);
        try {
            const fieldName = fieldMapping[editingField as keyof typeof fieldMapping];
            await authService.updateUserProfileField(user.auth_user_id, fieldName, fieldValue);
            setUser({ ...user, [fieldName]: fieldValue });
            setEditingField(null);
        } catch (err: any) {
            setError(err.message?.includes("duplicate key")
                ? `Este valor ya está en uso. Por favor ingrese un ${editingField.toLowerCase()} diferente.`
                : (err.message || "Error al actualizar el perfil"));
        } finally {
            setSubmitting(false);
        }
    };

    const InfoField = ({ label, value, editable, onEdit }: InfoFieldProps) => {
        const isNotAdded = !value || value === "No Añadido";
        const displayValue = isNotAdded ? "No Añadido" : value;

        if (editingField === label) {
            return (
                <Box sx={{ py: 2, borderBottom: 1, borderColor: "divider" }}>
                    <Typography variant="body1" sx={{ fontWeight: "medium", mb: 1 }}>
                        {`Editar ${label}`}
                    </Typography>
                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={fieldValue}
                        onChange={(e) => { setFieldValue(e.target.value); if (error) setError(null); }}
                        disabled={submitting}
                        sx={{ mb: 2 }}
                        inputRef={inputRef}
                        inputProps={{ autoFocus: true }}
                    />
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => setEditingField(null)} disabled={submitting} sx={{ mr: 1 }}>
                            Cancelar
                        </Button>
                        <Button
                            onClick={handleSaveField}
                            variant="contained"
                            disabled={submitting}
                            startIcon={submitting ? <CircularProgress size={20} /> : null}
                        >
                            {submitting ? 'Guardando...' : 'Guardar'}
                        </Button>
                    </Box>
                </Box>
            );
        }

        return (
            <Box sx={{ py: 2, borderBottom: 1, borderColor: "divider" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <Box>
                        <Typography variant="body1" sx={{ fontWeight: "medium", mb: 0.5 }}>
                            {label}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: isNotAdded ? "#856404" : "text.primary",
                                bgcolor: isNotAdded ? "#fff3cd" : "transparent",
                                display: isNotAdded ? "inline-block" : "block",
                                px: isNotAdded ? 1 : 0,
                                py: isNotAdded ? 0.5 : 0,
                                borderRadius: isNotAdded ? 1 : 0
                            }}
                        >
                            {displayValue}
                        </Typography>
                    </Box>
                    {editable && (
                        <Button variant="text" color="primary" onClick={onEdit}>
                            Editar
                        </Button>
                    )}
                </Box>
            </Box>
        );
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ py: 1 }}>
                <InfoField
                    label="Nombre"
                    value={user?.first_name || "No Añadido"}
                    editable
                    onEdit={() => handleEditField("Nombre", user?.first_name || "")}
                />
                <InfoField
                    label="Apellido"
                    value={user?.last_name || "No Añadido"}
                    editable
                    onEdit={() => handleEditField("Apellido", user?.last_name || "")}
                />
                <InfoField
                    label="DNI"
                    value={user?.document_number || "No Añadido"}
                    editable
                    onEdit={() => handleEditField("DNI", user?.document_number || "")}
                />
                <InfoField
                    label="Telefono"
                    value={user?.phone || "No Añadido"}
                    editable
                    onEdit={() => handleEditField("Telefono", user?.phone || "")}
                />
                <InfoField
                    label="Email"
                    value={user?.email || "No Añadido"}
                />
            </Box>
        </Box>
    );
}
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import useGlobalStore from "../../../state/store";
import isValidNonEmptyObject from "../../../shared/utils/isValidNonEmptyObject";
import { useState } from "react";
import { incidentService } from "../services/incidentService";
import useUser from "../../account/hooks/useUser";
import { useBuilding } from "../../building/hooks/useBuilding";
import { useIncident } from "./useIncident";

export default function useIncidents() {
  const setIncidents = useGlobalStore((state) => state.setIncidents);
  const incidents = useGlobalStore((state) => state.incidents);
  const hasCachedIncidents = isValidNonEmptyArray(incidents.data);

  const { updateIncidentStatus: updateIncidentStatusBase } = useIncident();

  const [error, setError] = useState(null);
  const { user } = useUser();
  const getIncidentById = (id: string) =>
    incidents.data.find((incident) => incident.id == id);

  const getIncidents = async ({ buildingId }: { buildingId: string }) => {
    if (!buildingId || !isValidNonEmptyObject(user)) return;

    try {
      setIncidents({ data: [], loading: true, error: null });

      const incidentResponses = await Promise.all([
        incidentService.fetchUserIncidents(user.id),
        incidentService.fetchCommunityIncidents(buildingId, user.id),
      ]);

      const incidents = incidentResponses
        .flat()
        .filter((incident) => incident.building_id === buildingId);

      setIncidents({ data: incidents, loading: false, error: null });
    } catch (err: any) {
      console.error("Error fetching incidents:", err);
      setError(err.message || "Error loading incidents");

      setIncidents({
        data: incidents.data,
        loading: false,
        error: "Error loading incidents",
      });
    }
  };

  const updateIncidentStatus = async ({
    incidentId,
    status,
    buildingId,
  }: {
    incidentId: string;
    status: string;
    buildingId: string;
  }) => {
    if (incidentId && buildingId && status) {
      try {
        await updateIncidentStatusBase(incidentId, status);
        await getIncidents({ buildingId });
      } catch (err: any) {
        setError(err.message || "Error updating incident status");
      }
    }
  };

  return {
    incidents,
    setIncidents,
    hasCachedIncidents,
    getIncidentById,
    error,
    getIncidents,
    updateIncidentStatus,
  };
}

// src/supabase/secureApi.ts

import { getSession } from "../features/auth/hooks/useSession";
import { supabase } from "./client";

export const secureApi = {
  ensureAuth() {
    const session = getSession();

    console.log("Cached session", { session });
    if (!session) {
      throw new Error("No active session");
    }
  },

  async get(table: string, options: any = {}) {
    this.ensureAuth();
    let query: any = supabase.from(table).select(options.select || "*");

    if (options.filter) {
      Object.entries(options.filter).forEach(([key, value]) => {
        query = query.eq(key, value);
      });
    }

    if (options.single) {
      query = query.single();
    }

    if (options.order) {
      Object.entries(options.order).forEach(([key, direction]) => {
        query = query.order(key, { ascending: direction === "asc" });
      });
    }

    const { data, error } = await query;

    if (error) {
      throw error;
    }

    return data;
  },

  async post(table: string, data: any) {
    this.ensureAuth();
    const { data: result, error } = await supabase
      .from(table)
      .insert(data)
      .select()
      .single();

    if (error) throw error;
    return result;
  },

  async update(table: string, id: string, data: any) {
    this.ensureAuth();
    const { data: result, error } = await supabase
      .from(table)
      .update(data)
      .eq("id", id)
      .select()
      .single();

    if (error) throw error;
    return result;
  },

  async delete(table: string, id: string) {
    this.ensureAuth();
    const { error } = await supabase.from(table).delete().eq("id", id);

    if (error) throw error;
  },

  async rpc(functionName: string, params: any) {
    this.ensureAuth();
    const { data, error } = await supabase.rpc(functionName, params);

    if (error) {
      throw error;
    }

    return data;
  },
};

import { Charge } from "../../../supabase/type/db-entities";

export default function findOldestInvoice(invoices: Charge[]) {
  if (!invoices || invoices.length === 0) {
    return null; // Handle empty or null input
  }

  let oldestInvoice = invoices[0];
  let oldestDate = new Date(invoices[0].issue_date);

  for (let i = 1; i < invoices.length; i++) {
    const currentInvoice = invoices[i];
    const currentDate = new Date(currentInvoice.issue_date);

    if (currentDate < oldestDate) {
      oldestDate = currentDate;
      oldestInvoice = currentInvoice;
    }
  }

  return oldestInvoice;
}

import { SnackbarCloseReason } from "@mui/material";
import { useState } from "react";

function useSnackbar() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return {
    openSnackbar: open,
    handleOpenSnackbar: handleOpen,
    handleCloseSnackbar: handleClose,
  };
}

export default useSnackbar;

import { Box } from "@mui/material";
import MessageGeneralWithoutBuilding from "../../shared/components/MessageGeneralWithoutBuilding";
import MaintenancePaymentSummary from "../maintenance/components/MaintenancePaymentSummary";
import { isValidNonEmptyArray } from "../../shared/utils/isValidNonEmptyArray";
import useUser from "../account/hooks/useUser";

export default function Summary() {
  const { user } = useUser();

  const hasAssociatedBuildings = isValidNonEmptyArray(user?.buildings);

  return (
    <Box>
      {hasAssociatedBuildings ? (
        <>
          <MaintenancePaymentSummary direction="row" />
          {/* <CardVisitsScheduled /> */}
        </>
      ) : (
        <MessageGeneralWithoutBuilding
          isResident={false}
          name={user?.first_name}
        />
      )}
    </Box>
  );
}

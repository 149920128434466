import { Box, IconButton, Select, Toolbar } from "@mui/material";
import { MenuIcon } from "lucide-react";
import { styled } from "@mui/material/styles";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { DRAWER_WIDTH } from "./MainSidebar";
import SelectBuildingApartment from "../../features/building/components/SelectBuildingApartment";
import ProfileMenu from "../components/ProfileMenu";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

type MainTopBarProps = {
  handleSidebarToggle: () => void;
};

function MainTopBar({ handleSidebarToggle }: MainTopBarProps) {
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "#F4F8FA",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleSidebarToggle}
            sx={{ mr: 2, color: "primary.main" }}
          >
            <MenuIcon />
          </IconButton>

          <img
            src={"/images/domus.png"}
            alt="Domus"
            style={{ height: "40px" }}
          />
        </Box>

        <Box>
          <SelectBuildingApartment />
          <ProfileMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default MainTopBar;

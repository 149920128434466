// src/features/auth/pages/Login.js

import { useState } from "react";
import { Typography, Box, Link, Snackbar } from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { useAuth } from "../hooks/useAuth";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { ROUTES } from "../../../config/routes";
import getSearchParams from "../../../shared/utils/getSearchParams";

interface LoginCredentials {
  email: string;
  password: string;
}

const Login = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectTo = getSearchParams(search, "redirect_to");

  const registerPath = redirectTo
    ? ROUTES.REGISTER
    : `${ROUTES.REGISTER}?${redirectTo}`;

  const handleLogin = async ({ email, password }: LoginCredentials) => {
    try {
      const success = await login(email, password);
      if (success) {
        navigate(redirectTo ? redirectTo : ROUTES.SUMMARY);
        setSnackbarMessage("Login successful");
      } else {
        setSnackbarMessage("Login failed");
      }
      setOpenSnackbar(true);
    } catch (error: any) {
      console.error("Login error:", error);
      setSnackbarMessage(error.message || "An error occurred during login");
      setOpenSnackbar(true);
    }
  };

  return (
    <Box sx={{ mt: 1, maxWidth: 400, margin: "auto", p: 2 }}>
      <Typography component="h1" variant="h5" gutterBottom>
        Iniciar sesión
      </Typography>
      <LoginForm onSubmit={handleLogin} />
      <Typography align="center">
        <Link
          component={RouterLink}
          to={ROUTES.FORGOT_PASSWORD}
          underline="hover"
        >
          Olvidé mi contraseña
        </Link>
      </Typography>
      <Typography align="center" sx={{ mt: 1 }}>
        No tengo cuenta,{" "}
        <Link component={RouterLink} to={registerPath} underline="hover">
          Registrarme
        </Link>
      </Typography>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default Login;

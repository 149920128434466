import { useState, useCallback } from "react";

const useClipboard = (timeout = 800) => {
  const [hasCopied, setHasCopied] = useState(false);
  const [copiedText, setCopiedText] = useState<string | null>(null);

  const copyToClipboard = useCallback(
    async (text: string) => {
      if (!text) {
        return;
      }

      try {
        // Use the modern clipboard API
        await navigator.clipboard.writeText(text);
        setHasCopied(true);
        setCopiedText(text);

        // Reset the copied state after timeout
        setTimeout(() => {
          setHasCopied(false);
        }, timeout);
      } catch (error) {
        // Fallback for older browsers
        const textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand("copy");
          setHasCopied(true);

          // Reset the copied state after timeout
          setTimeout(() => {
            setHasCopied(false);
            setCopiedText(null);
          }, timeout);
        } catch (err) {
          console.error("Failed to copy text:", err);
        }

        document.body.removeChild(textArea);
      }
    },
    [timeout]
  );

  return { hasCopied, copyToClipboard, copiedText };
};

export default useClipboard;

// src/features/maintenance/components/MaintenancePaymentSummary.tsx

import React, { Fragment, useEffect } from "react";
import useMaintenanceFees2 from "../../maintenance/hooks/useMaintenanceFees2";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
  Grid2 as Grid,
} from "@mui/material";
import theme from "../../../styles/theme";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

import { Link } from "react-router-dom";
import { ROUTES } from "../../../config/routes";
import formatPaymentPeriodDate, {
  formatMaintenanceDate,
} from "../utils/formatPaymentPeriodDate";

type MaintenancePaymentSummaryProps = {
  direction?: "row" | "column";
  apartmentId: string;
  buildingId: string;
};

function MaintenancePaymentSummary({
  direction = "row",
  apartmentId,
  buildingId,
}: MaintenancePaymentSummaryProps) {
  const {
    totalMaintenanceFees,
    maintenanceFeesNotFound,
    getMaintenanceFees,
    hasCachedTotalMaintainFees,
  } = useMaintenanceFees2();

  const { pathname } = useLocation();

  const horizontal = direction === "row";

  useEffect(() => {
    if (apartmentId && buildingId) {
      getMaintenanceFees({ apartmentId, buildingId });
    }
  }, [apartmentId, buildingId, getMaintenanceFees]);

  const ExpirationDate = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          mt: 1,
        }}
      >
        <Stack direction={"row"} spacing={1} flex={1} alignItems={"center"}>
          <Typography
            variant="h1"
            component="div"
            sx={{ fontSize: "1.1rem" }}
          >
            Fecha de vencimiento
          </Typography>

          {totalMaintenanceFees.loading ? (
            <Skeleton variant="rounded" width={120} height={20} />
          ) : (
            <Typography
              variant="h1"
              component="div"
              sx={{ fontSize: "1.1rem" }}
            >
              {totalMaintenanceFees.data.dueDate
                ? formatMaintenanceDate(totalMaintenanceFees.data.dueDate)
                : "N/A"}{" "}
            </Typography>
          )}
        </Stack>
      </Box>
    );
  };

  const RegisterVoucherButton = () => {
    if (!hasCachedTotalMaintainFees) return null;

    return (
      <Link to={ROUTES.RECORD_MAINTENANCE_PAYMENT + "?from=" + pathname}>
        <Button
          sx={{ width: "100%" }}
          variant="contained"
          startIcon={<ReceiptLongOutlinedIcon />}
        >
          Registrar Voucher
        </Button>
      </Link>
    );
  };

  const ViewReceiptButton = () => (
    <Button
      onClick={() =>
        window.open(
          "https://ruta-al-archivo.pdf",
          "_blank" // para abrir en una nueva pestaña
        )
      }
      variant="outlined"
      sx={{ width: "100%" }}
      startIcon={<RemoveRedEyeOutlinedIcon />}
    >
      Ver Recibo
    </Button>
  );

  return (
    <Fragment>
      <Card
        sx={{
          borderRadius: 4,
          p: 1,
          flex: 1,
          height: "100%",
        }}
      >
        <CardContent
          sx={{
            py: theme.spacing(1.5),
            px: theme.spacing(2),
            flex: 1,
            height: "100%",

            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            direction={horizontal ? "row" : "column"}
            spacing={2}
            height={"100%"}
          >
            <Grid
              xs={12}
              md={horizontal ? 8 : 12}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: "1.3rem",
                    color: "#315CAC",
                    fontWeight: "bold",
                  }}
                >
                  Cuota de mantenimiento
                </Typography>
              </Box>

              {maintenanceFeesNotFound ? (
                <Typography
                  variant="caption"
                  component="div"
                  sx={{ fontSize: "1rem", color: "#5D5E61" }}
                >
                  Sin cuotas de mantenimiento pendientes
                </Typography>
              ) : (
                <React.Fragment>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    flex={1}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontSize: "1rem" }}
                    >
                      Periodo:
                    </Typography>

                    {totalMaintenanceFees.loading ? (
                      <Skeleton variant="text" width={120} height={20} />
                    ) : totalMaintenanceFees.data.issueDate ? (
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontSize: "1rem" }}
                      >
                        {formatPaymentPeriodDate(
                          totalMaintenanceFees.data.issueDate
                        )}
                      </Typography>
                    ) : (
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontSize: "1rem" }}
                      >
                        N/A
                      </Typography>
                    )}
                  </Stack>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                          justifyContent: { md: "space-between" },
                          alignItems: "center",
                        }}
                      >
                        {totalMaintenanceFees.loading ? (
                          <Skeleton variant="text" width={210} height={60} />
                        ) : (
                          <Typography
                            variant="body1"
                            component="div"
                            sx={{
                              fontSize: "3rem",
                            }}
                          >
                            s/{" "}
                            {totalMaintenanceFees.data.totalAmount !== null
                              ? totalMaintenanceFees.data.totalAmount.toFixed(2)
                              : "0.00"}
                          </Typography>
                        )}
                      </Box>
                      <ExpirationDate />
                    </Box>
                  </Box>
                </React.Fragment>
              )}
            </Grid>
            <Grid
              xs={12}
              md={horizontal ? 4 : 12}
            >
              {!horizontal ? (
                <Grid
                  container
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                >
                  <Grid 
                    xs={12} 
                    md={6}
                    sx={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <ViewReceiptButton />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <RegisterVoucherButton />
                  </Grid>
                </Grid>
              ) : (
                <Grid xs={12}>
                  <RegisterVoucherButton />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/** Action buttons Vertical direction */}
        </CardContent>
      </Card>
    </Fragment>
  );
}

export default MaintenancePaymentSummary;

import React, { useCallback, useEffect } from "react";
import {
  Container,
  Box,
  CircularProgress,
  Alert,
  IconButton,
  Typography,
  Skeleton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useIncident } from "../hooks/useIncident";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { ROUTES } from "../../../config/routes";
import IncidentDetailView from "../components/detail/IncidentDetailView";
import useGlobalStore from "../../../state/store";
import IncidentDetailViewSkeleton from "../components/detail/IncidentDetailViewSkeleton";

const IncidentDetail = () => {
  const { id } = useParams();

  const user = useGlobalStore((state) => state.user);
  const { incident, loading, error, addComment, fetchIncident } =
    useIncident(id);

  // TODO: Implement update and rate again.
  // const handleUpdateStatus = async (incidentId: any, newStatus: any) => {
  //   try {
  //     await updateIncidentStatus(incidentId, newStatus);
  //   } catch (err) {
  //     console.error('Error updating status:', err);
  //   }
  // };

  const handleComment = async (commentText: any, mediaFiles: any) => {
    try {
      console.log("Submitting comment:", {
        commentText,
        mediaFiles,
        buildingId: incident.building_id, // Log the building_id
      });

      if (!incident.building_id) {
        throw new Error("Missing building ID");
      }

      await addComment(commentText, mediaFiles);
    } catch (err) {
      console.error("Error adding comment:", err);
    }
  };

  const handleRateResolution = useCallback((rating: any, comment: any) => {
    console.log("Rating:", rating, comment);
    // Implement rating logic here
  }, []);

  useEffect(() => {
    if (id) {
      fetchIncident();
    }
  }, [id]);

  if (!user) {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="warning">Please log in to view this incident.</Alert>
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  if (!incident && !loading) {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="info">Incident not found</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Box py={4}>
      <Box display="flex" alignItems="center" mb={3} flex={1} width={"100%"}>
        <Link
          style={{ textDecoration: "inherit", color: "inherit" }}
          to={ROUTES.INCIDENTS}
        >
          <IconButton sx={{ mr: 2 }}>
            <ArrowBack />
          </IconButton>
        </Link>

        <Box width={"100%"}>
          {loading ? (
            <Skeleton width={"55%"} height={32} variant="text" />
          ) : (
            <Typography variant="h5">{incident.title}</Typography>
          )}
        </Box>
      </Box>

      {loading ? (
        <IncidentDetailViewSkeleton />
      ) : (
        <IncidentDetailView
          incident={incident}
          onAddComment={handleComment}
          onRateResolution={handleRateResolution}
        />
      )}
    </Box>
  );
};

export default IncidentDetail;

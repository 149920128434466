import { MONTHS_IN_SPANISH } from "../../../constants/months-in-spanish";

export default function formatPaymentPeriodDate(isoDate: string) {
  const date = new Date(isoDate);

  const month = MONTHS_IN_SPANISH[date.getMonth()];
  const year = date.getFullYear();
  return `${month} ${year}`;
}

export const formatMaintenanceDate = (originalDate: string) => {
  const date = new Date(originalDate || "");
  const day = date.getDate();
  const month = MONTHS_IN_SPANISH[date.getMonth()];
  const year = date.getFullYear();

  return `${day} de ${month} ${year}`;
};

//src/features/maintenances/hooks/usePayments.ts
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import useGlobalStore from "../../../state/store";
import { secureApi } from "../../../supabase/secureApi";

function usePayments() {
  const payments = useGlobalStore((state) => state.payments);
  const setPayments = useGlobalStore((state) => state.setPayments);
  const hasCachedPayments = isValidNonEmptyArray(payments.data);

  const getPayments = async ({
    buildingId,
    apartmentId,
  }: {
    buildingId: string;
    apartmentId: string;
  }) => {
    if (buildingId && apartmentId) {
      try {
        setPayments({ data: [], loading: true, error: null });
        const data = await secureApi.get("payments", {
          select: "*",
          filter: { building_id: buildingId, apartment_id: apartmentId },
          order: { created_at: { direction: "desc" } },
        });

        setPayments({
          data: isValidNonEmptyArray(data) ? data : [],
          loading: false,
          error: null,
        });
      } catch (error) {
        setPayments({
          data: payments.data,
          loading: false,
          error: "Failed to fetch building details",
        });
        console.error("Failed to fetch building details:", error);
        throw new Error("Failed to fetch building details");
      }
    }
  };

  const findPaymentById = (id: string) =>
    payments?.data?.find((payment) => payment.id === id) ?? null;

  return { payments, getPayments, findPaymentById, hasCachedPayments };
}

export default usePayments;
